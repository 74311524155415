import { useRef } from 'react'
import { PressEvent, useButton, useHover, usePress } from 'react-aria'
import { tv, VariantProps } from 'tailwind-variants'

const AriaButtonTV = tv({
  slots: {
    base: 'relative',
    text: 'w-full',
  },
  variants: {
    size: {
      sm: { text: 'text-sm' },
      md: { text: 'text-md' },
      lg: { text: 'text-lg' },
      xl: { text: 'text-xl' },
      xxl: { text: 'text-2xl' },
      xxxl: { text: 'text-3xl' },
      xxxxl: { text: 'text-4xl' },
      xxxxxl: { text: 'text-5xl' },
      xxxxxxl: { text: 'text-6xl' },
    },
    disabled: {
      true: 'cursor-not-allowed opacity-50',
    },
    theme: {
      selected: 'bg-accent text-white border-b-2 border-shadow',
      primary: 'bg-accent text-white hover:bg-highlight hover:shadow-xl',
      secondary: 'bg-midtone text-white hover:bg-highlight hover:shadow-xl',
      neutral: 'bg-white text-black hover:text-white hover:bg-highlight hover:shadow-xl border border-highlight',
      danger: 'bg-red-600 text-white  hover:bg-red-400 hover:shadow-xl',
      none: 'text-white hover:text-gray-300 hover:border-none focus:outline-none ',
    },
  },
  defaultVariants: {
    size: 'md',
    theme: 'none',
  },
})

type AriaButtonVariants = VariantProps<typeof AriaButtonTV>

interface AriaButtonProps extends AriaButtonVariants {
  className?: string
  children?: React.ReactNode
  type?: 'button' | 'submit' | 'reset'
  title?: string | undefined
  onPress?: (e: PressEvent) => void
  onPressStart?: (e: PressEvent) => void
  onPressEnd?: (e: PressEvent) => void
}

const AriaButton: React.FC<AriaButtonProps> = (props: AriaButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const { buttonProps } = useButton(props, buttonRef)

  const { hoverProps } = useHover({
    onHoverStart: () => {},
    onHoverEnd: () => {},
  })

  const { pressProps } = usePress({
    onPressStart: (e: any) => props.onPressStart && props.onPressStart(e),
    onPressEnd: (e: any) => props.onPressEnd && props.onPressEnd(e),
    onPress: (e: any) => props.onPress && props.onPress(e),
  })

  // remove the className, so that it isn't spread anywhere but the root
  const { className, ...propsNoClassName } = props

  const { base, text } = AriaButtonTV(props)
  return (
    <>
      <button
        title={props.title}
        type={props.type ?? 'button'}
        className={base(props)}
        disabled={props.disabled}
        {...buttonProps}
        {...hoverProps}
        {...pressProps}
        ref={buttonRef}
      >
        <span className={text(propsNoClassName)}>{props.children}</span>
      </button>
    </>
  )
}

export default AriaButton

import { useEffect, useState } from 'react'
import { Breadcrumb, Breadcrumbs, type Key } from 'react-aria-components'
import { FaChevronRight } from 'react-icons/fa'
import { IconType } from 'react-icons/lib'
import { Link } from 'react-router-dom'
import { tv, type VariantProps } from 'tailwind-variants'

const breadCrumbTV = tv({
  slots: {
    base: 'w-full flex flex-row justify-center',
    url: 'hover:text-shadow',
  },
  variants: {
    textSize: {
      xs: {
        url: 'text-sm',
      },
      sm: {
        url: 'text-sm',
      },
      md: {
        url: 'text-md',
      },
      lg: {
        url: 'text-lg',
      },
      xl: {
        url: 'text-xl',
      },
      xxl: {
        url: 'text-2xl',
      },
    },
    theme: {
      primary: {
        base: 'bg-accent text-white',
      },
      secondary: {
        base: 'bg-midtone text-white',
      },
      neutral: {
        base: 'bg-white text-gray-800',
        url: 'hover:text-midtone',
      },
      danger: {
        base: 'bg-red-600 text-white',
      },
    },
  },
  defaultVariants: {
    textSize: 'md',
    theme: 'secondary',
  },
})

type BreadCrumbVariants = VariantProps<typeof breadCrumbTV>

export type TBreadCrumb = {
  id: string
  label: string
  href?: string
  icon?: IconType
}

interface BreadCrumbsProps extends BreadCrumbVariants {
  allBreadCrumbs: Array<TBreadCrumb>
  onNavigate?: (id: Key) => void
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = (props: BreadCrumbsProps) => {
  const { base, url } = breadCrumbTV(props)

  const [breadcrumbs, setBreadCrumbs] = useState<Array<TBreadCrumb>>([])

  useEffect(() => {
    setBreadCrumbs([...props.allBreadCrumbs])
  }, [props.allBreadCrumbs])

  const isNotLast = (id: Key): boolean => breadcrumbs.findIndex(bc => bc.id === id) !== breadcrumbs.length - 1

  return (
    <Breadcrumbs className={base(props)} items={breadcrumbs}>
      {item => (
        <Breadcrumb className="text-lg flex flex-row flex-nowrap gap-2 items-center cursor-pointer">
          <Link
            className={url(props)}
            to={item.href ?? ''}
            onClick={() => props?.onNavigate && props?.onNavigate(item.id)}
          >
            {item.icon && <item.icon></item.icon>}
            {item.label.length > 0 && <div>{item.label}</div>}
          </Link>
          {isNotLast(item.id) && (
            <span className="text-md mr-3">
              <FaChevronRight className="h-3 w-3 flex-shrink-0" />
            </span>
          )}
        </Breadcrumb>
      )}
    </Breadcrumbs>
  )
}

export default BreadCrumbs

import { useNavigate } from 'react-router-dom'
import { Button } from '../../components'
import { useEffect, useState } from 'react'
import { useAppContext, useAuthContext } from '../../providers'

interface AdminProps {}

const Admin: React.FC<AdminProps> = () => {
  const { setShowDebug } = useAppContext()
  const navigate = useNavigate()
  const { authUser } = useAuthContext()

  const [debug, setDebug] = useState<boolean>(false)

  useEffect(() => {
    if (!authUser || !authUser.isAdmin) {
      navigate('/', { replace: true })
    }
  }, [navigate, authUser])

  if (!authUser || !authUser.isAdmin) {
    return null
  }

  return (
    <>
      {authUser?.isAdmin && (
        <div className="flex justify-between">
          <div className="flex">
            <Button className="p-2 m-2 min-w-10" theme="secondary" onPress={() => navigate('/users/admin')}>
              Users
            </Button>
            <Button className="p-2 m-2 min-w-10" theme="secondary" onPress={() => navigate('/pools/admin')}>
              Pools
            </Button>
          </div>
          <Button
            className="p-2 m-2 min-w-10"
            theme="secondary"
            onPress={() => {
              setDebug(!debug)
              setShowDebug(!debug)
            }}
          >
            Back
          </Button>
        </div>
      )}
    </>
  )
}

export default Admin

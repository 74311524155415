import { TProviderIds } from 'app-platform-shared-types'
import { tv, VariantProps } from 'tailwind-variants'
import AriaButton from '../../../../components/core/AriaButton'
import clsx from 'clsx'
import { MdLink } from 'react-icons/md'

const ExternalProvidersTV = tv({
  slots: {
    base: 'w-full flex flex-row justify-start items-center flex-wrap gap-1 ',
    text: 'overflow-hidden text-nowrap',
  },
  variants: {
    size: {
      xs: { text: 'text-xs' },
      sm: { text: 'text-sm' },
      md: { text: 'text-md' },
      lg: { text: 'text-lg' },
      xl: { text: 'text-xl' },
      xxl: { text: 'text-2xl' },
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type ExternalProvidersVariants = VariantProps<typeof ExternalProvidersTV>

interface ExternalProvidersProps extends ExternalProvidersVariants {
  providerIds: TProviderIds
  className?: string
  children?: React.ReactNode
}

const getProviderUrl = (providerId: string, providerIds: TProviderIds): string => {
  let url: string = ''
  switch (providerId) {
    case 'Facebook':
      url = `https://www.facebook.com/${providerIds.Facebook}`
      break
    case 'Instagram':
      url = `https://www.instagram.com/${providerIds.Instagram}`
      break
    case 'Imdb':
    case 'IMDB':
      url = `https://www.imdb.com/title/${providerIds.Imdb ?? providerIds.IMDB}`
      break
    case 'Tvdb':
      url = `https://thetvdb.com/dereferrer/movie/${providerIds.Tvdb}`
      break
    case 'Tmdb':
      url = `https://www.themoviedb.org/movie/${providerIds.Tmdb}`
      break
    case 'Official Website':
      url = providerIds['Official Website'] ?? ''
      break
    case 'X (Twitter)':
      url = `https://www.x.com/${providerIds['X (Twitter)']}`
      break

    default:
      // console.log('ExternalProviders: mismatched provider', providerId)
      break
  }

  return url
}

const ExternalProviders: React.FC<ExternalProvidersProps> = ({ className, children, ...props }) => {
  const { base, text } = ExternalProvidersTV(props)
  // console.log('ExternalProviders providerIds', props.providerIds)
  return (
    <div className={base(props)}>
      {Object.keys(props.providerIds).map(pi => {
        // console.log('ProviderIds', props.providerIds)
        const providerUrl = getProviderUrl(pi, props.providerIds)
        if (!providerUrl.length) {
          return null
        }
        let result: React.ReactNode
        result = (
          <AriaButton
            key={pi}
            theme="secondary"
            size={props.size as 'sm' | 'md' | 'lg' | 'xl' | 'xxl'}
            className={clsx('flex flex-row justify-between items-center gap-1 m-1 px-2 rounded-lg', text(props))}
            onPress={() => window.open(providerUrl, '_blank', 'noopener,noreferrer')}
          >
            <div className="w-full flex flex-row flex-nowrap items-center gap-1">
              <MdLink />
              <span>{pi}</span>
            </div>
          </AriaButton>
        )

        return result
      })}
    </div>
  )
}

export default ExternalProviders

import clsx from 'clsx'
import { useState, useRef, useEffect } from 'react'
import { tv, VariantProps } from 'tailwind-variants'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6'
import AriaButton from './AriaButton'
import { PressEvent, useLongPress, usePress } from 'react-aria'
import { ListBox, ListBoxItem } from 'react-aria-components'
import { useListData } from 'react-stately'
import { useTranslation } from 'react-i18next'
const CarouselTV = tv({
  slots: {
    base: 'relative w-full flex flex-row flex-nowrap gap-2 scrollbar-hide overflow-scroll',
    item: 'bg-white rounded flex-shrink-0',
    button:
      'z-20 bg-transparent flex flex-col justify-center absolute top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:bg-shadow group-hover:bg-opacity-40  transition-opacity duration-300 h-full w-[55px]',
  },
  variants: {
    itemSize: {
      xs: {
        item: 'w-[125px]',
      },
      sm: {
        item: 'w-[150px]',
      },
      md: {
        item: 'w-[175px]',
      },
      lg: {
        item: 'w-[200px]',
      },
      xl: {
        item: 'w-[225px]',
      },
      xxl: {
        item: 'w-[250px]',
      },
    },
    outerPadding: {
      xs: {
        base: 'mx-1',
      },
      sm: {
        base: 'mx-2',
      },
      md: {
        base: 'mx-3',
      },
      lg: {
        base: 'mx-4',
      },
      xl: {
        base: 'mx-5',
      },
      xxl: {
        base: 'mx-6',
      },
    },
  },
  defaultVariants: {
    itemSize: 'md',
  },
})

type CarouselVariants = VariantProps<typeof CarouselTV>

interface CarouselProps extends CarouselVariants {
  children: Array<React.ReactNode>
  onPress?: (e: PressEvent) => void
}

type ScrollDirection = 'left' | 'right'

const Carousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  const { t } = useTranslation()
  const [scrollOffset, setScrollOffset] = useState<number>(0)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const { base, item, button } = CarouselTV(props)

  const { pressProps: containerPressProps } = usePress({
    onPress: (e: PressEvent) => {
      props.onPress && props.onPress(e)
      console.log('containerPressProps:onPress', e)
    },
    onPressStart: (e: PressEvent) => {
      console.log('containerPressProps:onPressStart', e)
    },
    onPressEnd: (e: PressEvent) => {
      console.log('containerPressProps:onPressEnd', e)
    },
  })

  const { longPressProps: itemContainerLongPressProps } = useLongPress({
    onLongPress: (e: any) => {
      console.log('containerPress:onLongPress', e)
    },
    onLongPressStart: (e: any) => {
      console.log('containerPress:onLongPressStart', e)
    },
    onLongPressEnd: (e: any) => {
      console.log('containerPress:onLongPressEnd', e)
    },
    threshold: 30000,
  })

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollOffset,
        behavior: 'smooth',
      })
    }
  }, [scrollOffset])

  const handleScroll = (direction: ScrollDirection) => {
    const screenWidth = window.innerWidth
    const scrollAmount = screenWidth * 0.8 // Adjust to 80% of the screen width
    const containerWidth = scrollContainerRef?.current?.scrollWidth ?? 0
    setScrollOffset(prevOffset => {
      const newOffset = direction === 'left' ? prevOffset - scrollAmount : prevOffset + scrollAmount

      const newScrollOffset = Math.min(Math.max(newOffset, 0), containerWidth - screenWidth)

      return newScrollOffset
    })
  }

  const renderedJsxItems: Array<{ id: string | number; jsx: React.ReactNode }> = props.children?.map((node, index) => {
    return {
      id: index,
      jsx: node,
    }
  })
  const list = useListData({
    initialItems: renderedJsxItems,
    initialSelectedKeys: [],
    getKey: item => item.id,
  })

  return (
    <div className={clsx('w-full relative group')}>
      {/* Left Button */}
      <AriaButton size="xxxxxxl" className={clsx(button(props), 'left-0')} onPress={() => handleScroll('left')}>
        <FaChevronLeft />
      </AriaButton>

      {/* Scroll Container */}
      <div className={clsx(base(props))} ref={scrollContainerRef} {...itemContainerLongPressProps}>
        {props.children?.length && (
          <ListBox items={list.items} className="w-full flex flex-row gap-2" aria-label="Item Carousel">
            {/* {cItem => (
              <ListBoxItem key={cItem.id} className={item(props)} {...containerPressProps} textValue="Carousel Item">
                {cItem.jsx}
              </ListBoxItem>
            )} */}
            {list.items?.length &&
              list.items.map(cItem => (
                <ListBoxItem key={cItem.id} className={item(props)} {...containerPressProps} textValue="Carousel Item">
                  {cItem.jsx}
                </ListBoxItem>
              ))}
          </ListBox>
        )}
      </div>

      {/* Right Button */}
      <AriaButton size="xxxxxxl" className={clsx(button(props), 'right-0')} onPress={() => handleScroll('right')}>
        <FaChevronRight />
      </AriaButton>
    </div>
  )
}

export default Carousel

import { TEmbyBaseItemDto } from 'app-platform-shared-types'
import { ClientConfig } from '../../config/client.config'

type TItemImageMeta = {
  type: 'Primary' | 'Logo'
  url: string
}

const IMAGE_QUALITY: number = 90
const DEFAULT_PRIMARY_IMAGE_ASPECT_RATIO: number = 0.6666

export const getItemImgWithETag = (
  id: string,
  imgTag: string,
  quality: number,
  imageType: string,
  primaryAspectRatio?: number,
) => {
  const url = `${ClientConfig.publicEmbyPublicImgUrl}/Items/${id}/Images/${imageType}?ETag=${imgTag}&quality=${quality}&PrimaryImageAspectRatio=${primaryAspectRatio ?? DEFAULT_PRIMARY_IMAGE_ASPECT_RATIO}`
  return url
}

export const getItemImgDirect = (id: string, quality: number, imageType: string, primaryAspectRatio?: number) => {
  return `${ClientConfig.publicEmbyPublicImgUrl}/Items/${id}/Images/${imageType}?quality=${quality}&PrimaryImageAspectRatio=${primaryAspectRatio ?? DEFAULT_PRIMARY_IMAGE_ASPECT_RATIO}`
}

export const getImgUrls = (item: TEmbyBaseItemDto, size: number): Array<TItemImageMeta> => {
  const imageUrls = Object.keys(item.ImageTags).map(key => {
    const url = `${ClientConfig.publicEmbyPublicImgUrl}/Items/${item.Id}/Images/${key}?Width=${size}`
    return {
      type: key,
      url,
    } as TItemImageMeta
  })

  return imageUrls
}

export const getPrimaryUrl = (item: TEmbyBaseItemDto): string => {
  if (!item.ImageTags?.Primary) {
    return ''
  }

  return getItemImgWithETag(item.Id, item.ImageTags?.Primary, IMAGE_QUALITY, 'Primary', item.PrimaryImageAspectRatio)
}

export const getLogoUrl = (item: TEmbyBaseItemDto): string => {
  if (!item.ImageTags?.Logo) {
    return ''
  }

  return getItemImgWithETag(item.Id, item.ImageTags?.Logo, IMAGE_QUALITY, 'Logo', item.PrimaryImageAspectRatio)
}

export const getBackdropUrls = (item: TEmbyBaseItemDto): Array<string> => {
  const backdropImgUrls = Object.values(item.BackdropImageTags).map((backdropImgTag: string) => {
    const url = getItemImgWithETag(item.Id, backdropImgTag, IMAGE_QUALITY, 'Backdrop', item.PrimaryImageAspectRatio)
    return url
  })

  return backdropImgUrls
}

import { TEmbyBaseItemDto, TZoomSizes } from 'app-platform-shared-types'
import { ListBox, ListBoxItem } from 'react-aria-components'
import { tv, VariantProps } from 'tailwind-variants'
import MediaItem from './MediaItem'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useListData } from 'react-stately'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../../../providers'

const MediaItemsTV = tv({
  slots: {
    base: 'relative w-full flex flex-row',
  },
  variants: {
    size: {
      sm: 'text-sm',
      md: 'text-md',
      lg: 'text-lg',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type MediaItemsVariants = VariantProps<typeof MediaItemsTV>

interface MediaItemsProps extends MediaItemsVariants {
  mediaItems: Array<TEmbyBaseItemDto>
  onPress: (item: TEmbyBaseItemDto) => void
  className?: string
  zoomSizeOverride?: TZoomSizes
}

const MediaItems: React.FC<MediaItemsProps> = ({ mediaItems, onPress, className, zoomSizeOverride }) => {
  const { t } = useTranslation()
  const list = useListData({
    initialItems: mediaItems,
    initialSelectedKeys: [],
    getKey: item => item.Id,
  })

  const { zoomSize } = useAppContext()

  useEffect(() => {
    const idsToRemove = list.items.map(i => i.Id)
    list.remove(...idsToRemove)
    mediaItems.forEach((mi, index) => {
      list.append(mi)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaItems])

  return (
    <>
      <ListBox
        items={list.items}
        className={clsx('flex flex-row justify-center flex-wrap gap-4 m-2', className)}
        aria-label={t('emby.mediaItems')}
      >
        {/* MAP works better with reponsive parameters
        {item => (
          <ListBoxItem id={item?.Id}>
            <MediaItem
              mediaItem={item}
              key={item?.Id}
              size={zoomSize}
              onPress={(item: TEmbyBaseItemDto) => onPress(item)}
            />
          </ListBoxItem>
        )} */}
        {list.items.map(item => (
          <ListBoxItem id={item?.Id} key={item?.Id} textValue={item.Name}>
            <MediaItem
              mediaItem={item}
              key={item?.Id}
              size={zoomSize}
              onPress={(item: TEmbyBaseItemDto) => onPress(item)}
            />
          </ListBoxItem>
        ))}
      </ListBox>
    </>
  )
}

export default MediaItems

import { useNavigate } from 'react-router-dom'
import { tv, VariantProps } from 'tailwind-variants'
import AriaButton from './AriaButton'
import { FaArrowLeft } from 'react-icons/fa6'
import clsx from 'clsx'

const BackButtonTV = tv({
  slots: {
    base: 'm-2 cursor-pointer rounded-full flex flex-row items-center',
    text: 'rounded-full p-1',
  },
  variants: {
    size: {},
  },
  defaultVariants: {
    // size: 'md',
  },
})

type BackButtonVariants = VariantProps<typeof BackButtonTV>

interface BackButtonProps extends BackButtonVariants {
  className?: string
}

const BackButton: React.FC<BackButtonProps> = ({ className, ...props }) => {
  const navigate = useNavigate()

  const { base, text } = BackButtonTV(props)
  return (
    <AriaButton theme="secondary" size="xxl" className={clsx(base(props), className)} onPress={() => navigate(-1)}>
      <FaArrowLeft className={text(props)} />
    </AriaButton>
  )
}

export default BackButton

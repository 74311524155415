import React, { useRef, useEffect, ReactNode } from 'react'
import clsx from 'clsx'
import { Button } from '..'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'

type DialogProps = {
  isOpen: boolean
  showBackdrop?: boolean // Optional prop for backdrop
  children: ReactNode
  dialogTitle?: string
  actionButtonLabel?: string
  actionButtonClick?: () => void
  cancelButtonLabel?: string
  cancelButtonClick?: () => void
  className?: string
}

const Dialog: React.FC<DialogProps> = ({
  isOpen,
  showBackdrop = true,
  children,
  className,
  dialogTitle,
  actionButtonLabel,
  actionButtonClick,
  cancelButtonLabel,
  cancelButtonClick,
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (dialogRef.current) {
      if (isOpen) {
        dialogRef.current.showModal()
      } else {
        dialogRef.current.close()
      }
    }
  }, [isOpen])

  return (
    <div className="relative">
      {showBackdrop && isOpen && <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10"></div>}
      <dialog
        ref={dialogRef}
        className={clsx(
          'fixed z-20 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 p-0 m-0 border border-gray-300 rounded-md bg-white',
          className,
        )}
      >
        <div className="w-full h-full flex flex-col justify-between relative">
          <div className="flex flex-row justify-between items-center text-xl font-bold text-center border-b m-1">
            <div></div>
            <div>{dialogTitle}</div>
            <div>
              <Button theme="neutral" className="m-1" onPress={cancelButtonClick}>
                <FaXmark />
              </Button>
            </div>
          </div>
          <div className="grow m-2 overflow-y-auto">{children}</div>
          <div className="flex flex-row justify-end gap-1 m-2 border-t">
            {cancelButtonClick && (
              <Button className="p-2" theme="secondary" onPress={cancelButtonClick}>
                {cancelButtonLabel ?? t('common.cancel')}
              </Button>
            )}
            {actionButtonClick && (
              <Button className="p-2" theme="primary" onPress={actionButtonClick}>
                {actionButtonLabel ?? t('common.ok')}
              </Button>
            )}
          </div>
        </div>
      </dialog>
    </div>
  )
}

export default Dialog

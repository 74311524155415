import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { FaEllipsis } from 'react-icons/fa6'
import ReactPaginate from 'react-paginate'

interface PaginateProps {
  forcePage?: number
  pageCount: number
  onPageChange: (page: number) => void
}

const Paginate: React.FC<PaginateProps> = (props: PaginateProps) => {
  const { t } = useTranslation()
  // console.log('Paginate Params:', props)
  return (
    <>
      <ReactPaginate
        forcePage={props.forcePage}
        className="flex flex-row "
        pageCount={props.pageCount}
        onPageChange={e => props.onPageChange(e.selected)}
        previousLabel={
          <span className="p-1 text-sm h-10 flex justify-center items-center border border-gray-300">
            <FaArrowLeft />
            &nbsp;
            {t('paginator.previous')}
          </span>
        }
        previousAriaLabel={t('paginator.previous')}
        nextLabel={
          <span className="p-1 text-sm h-10 flex justify-center items-center border border-gray-300">
            {t('paginator.next')}&nbsp;
            <FaArrowRight />
          </span>
        }
        nextAriaLabel={t('paginator.next')}
        pageClassName="w-10 h-10 flex justify-center items-center border border-gray-300" //li items
        disabledClassName="text-gray-900 opacity-50"
        disabledLinkClassName="cursor-not-allowed"
        activeClassName="bg-highlight"
        breakLabel={
          <span className="p-1 text-sm w-10 h-10 flex justify-center items-center border border-gray-300">
            <FaEllipsis />
          </span>
        }
        pageLinkClassName="block w-full text-center"
        previousLinkClassName="block w-full text-center"
        nextLinkClassName="block w-full text-center"
      />
    </>
  )
}

export default Paginate

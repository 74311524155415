import { useEffect, useState } from 'react'
import { Paginate } from '../../../components'
import { getMediaItems } from '../../../lib/api/emby-fetch'
import { TApiResponse, TApiResponseMeta, TEmbyBaseItemDto } from 'app-platform-shared-types'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import EmbyLayout from '../../../layouts/EmbyLayout'
import MediaItems from './_components/MediaItems'
import { numberFromStr } from '../../../lib/helpers/utility.helper'
import AriaButton from '../../../components/core/AriaButton'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

interface EmbyProps {}

const getPageNum = (searchParamSkip: number, searchParamTake: number) =>
  Math.ceil((searchParamSkip * 1.0) / searchParamTake)

const fetchMedia = async ({ queryKey }: any): Promise<TApiResponse<TEmbyBaseItemDto> | undefined> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, { skip, take, parentId }] = queryKey
  const result = await getMediaItems({ skip: skip, take: take, parentId: parentId }).then(r => {
    return r
  })

  return result
}

const TV_ROOT_ID = '4'
const MOVIE_ROOT_ID = '6'
const allowedRootIds = [TV_ROOT_ID, MOVIE_ROOT_ID]

const fetchRootItems = async ({ queryKey }: any): Promise<TApiResponse<TEmbyBaseItemDto> | undefined> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, { parentId }] = queryKey
  const result = await getMediaItems().then(response => {
    if (response?.data?.length) {
      response.data = response?.data.length ? response.data.filter(d => allowedRootIds.indexOf(d.Id) !== -1) : []
    }
    return response
  })

  return result
}

const defaultParentId = MOVIE_ROOT_ID
const defaultSkip = 0
const defaultTake = 20
const defaultPageNum = 0
const defaultCollectionId = MOVIE_ROOT_ID

const Emby: React.FC<EmbyProps> = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const searchCollectionId = searchParams.get('collectionId') || defaultCollectionId.toString()
  const searchParamParentId = searchParams.get('parentId') || defaultParentId
  const searchParamSkip = numberFromStr(searchParams.get('skip')) || defaultSkip
  const searchParamTake = numberFromStr(searchParams.get('take')) || defaultTake
  const [collectionId, setCollectionId] = useState<string>(searchCollectionId)
  const [parentId, setParentId] = useState<string>(searchParamParentId)
  const [skip, setSkip] = useState<number>(searchParamSkip)
  const [take, setTake] = useState<number>(searchParamTake)
  const [page, setPage] = useState<number>(getPageNum(searchParamSkip, searchParamTake))

  const updateSearchParams = (params: {
    collectionId?: string
    parentId?: string
    skip?: number
    take?: number
    page?: number
  }) => {
    const newCollectionId = params.collectionId ?? defaultCollectionId
    const newParentId = params.parentId ?? defaultParentId
    const newSkip = params.skip ?? defaultSkip
    const newTake = params.take ?? defaultTake
    const newPage = params.page !== undefined && params.page >= 0 ? params.page : defaultPageNum
    setCollectionId(newCollectionId)
    setParentId(newParentId)
    setSkip(newSkip)
    setTake(newTake)
    setPage(newPage)
    setSearchParams({
      collectionId: newCollectionId,
      parentId: newParentId.toString(),
      skip: newSkip.toString(),
      take: newTake.toString(),
    })
  }

  useEffect(() => {
    // Perform action when URL (query params) changes
    const currParentId = searchParams.get('parentId') || `${defaultParentId}`
    const currentTake = parseInt(searchParams.get('take') || `${defaultTake}`, 10)
    const currentSkip = parseInt(searchParams.get('skip') || `${defaultSkip}`, 10)

    setParentId(currParentId)
    setTake(currentTake)
    setSkip(currentSkip)
    setPage(getPageNum(currentSkip, currentTake))
  }, [searchParams])

  const useMediaItems = () =>
    useQuery({
      queryKey: ['mediaItems', { skip, take, parentId }],
      queryFn: fetchMedia,
    })

  const useRootMediaItems = () =>
    useQuery({
      queryKey: ['rootMediaItems', { parentId: defaultParentId }],
      queryFn: fetchRootItems,
    })

  // const { status, data, error, isFetching, isLoading } = useMediaItems()
  const query = useMediaItems() as UseQueryResult<TApiResponse<TEmbyBaseItemDto> | undefined, Error>
  const { refetch, isPending, isFetching, isFetched, isError, error } = query
  // console.log('Emby, useMediaItems: isPending', isPending)
  // console.log('Emby, useMediaItems: isFetching', isFetching)
  // console.log('Emby, useMediaItems: isFetched', isFetched)

  const rootMediaItemsQuery = useRootMediaItems()
  const rootMediaItems: Array<TEmbyBaseItemDto> | undefined = rootMediaItemsQuery.data?.data

  if (isError) {
    console.error('Emby fetch error', error)
    return <div>Error</div>
  }

  const mediaItems: Array<TEmbyBaseItemDto> | undefined = query.data?.data
  const meta: TApiResponseMeta | undefined = query.data?.meta
  const pageCount = meta?.pagination.pageCount ?? 0

  return (
    <>
      {/* <LoadingOverlay show={isPending} text={t('common.loading')} /> */}
      <div className="w-full flex flex-row flex-nowrap justify-center my-1">
        {rootMediaItems?.map((rmi, index) => (
          <AriaButton
            key={rmi.Id}
            onPress={() => {
              if (!isNaN(parseInt(rmi.Id))) {
                updateSearchParams({ collectionId: rmi.Id, parentId: rmi.Id })
              }
            }}
            // theme="none"
            theme={rmi.Id === collectionId ? 'selected' : 'neutral'}
            className={clsx('p-2 min-w-[175px]', rmi.Id === collectionId && 'border-b-2 border-b-gray-500')}
            title={rmi.Name}
          >
            {rmi.Name}
          </AriaButton>
        ))}
      </div>

      {/* <div className="flex flex-row gap-6 justify-center items-center">
        <AriaButton onPress={() => refetch()} theme="neutral" className="p-2 border-none" title={t('common.refresh')}>
          <RiRefreshLine />
        </AriaButton>
      </div> */}
      <EmbyLayout bannerTitleText="EMBY">
        {pageCount > 1 && (
          <>
            <div className="w-full flex justify-center my-1">
              <Paginate
                forcePage={page}
                pageCount={pageCount}
                onPageChange={(pageNum: number) => {
                  updateSearchParams({ collectionId, parentId, skip: pageNum * take, take, page: pageNum })
                }}
              />
            </div>
          </>
        )}
        <MediaItems
          mediaItems={mediaItems ?? []}
          // zoomSizeOverride={'xs'}
          onPress={(item: TEmbyBaseItemDto): void => {
            if (item.Type === 'Series' || item.Type === 'Movie') {
              // console.log('Emby.tsx navigate by Series or Movie')
              navigate(`item/${item.Id}`)
            } else if (item.IsFolder) {
              // console.log('Emby.tsx navigate by folder')
              // navigate(`/emby/${item.Id}`)
              updateSearchParams({ parentId: item.Id })
            } else {
              console.log('Emby.tsx: unmapped item.Type', item.Type)
            }
          }}
        />
      </EmbyLayout>
    </>
  )
}

export default Emby

export const sanitizeInputHtml = (html: string): string => {
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}

export const formatCurrency = (amt: number): string => {
  const currencySign = '$'
  let result = ''
  let isNegative = false
  if (!isNaN(amt)) {
    isNegative = amt < 0
    amt = isNegative ? amt * -1 : amt
    result = amt.toFixed(2)
  }

  return `${isNegative ? '-' : ''}${currencySign}${result}`
}

export const numberFromStr = (numStr?: string | undefined | null): number | undefined => {
  let result: number | undefined
  if (numStr?.length && numStr.length > 0) {
    result = parseInt(numStr, 10)
  }

  if (result && isNaN(result)) {
    throw new Error('numStr is NaaN')
  }

  return result
}

export const hourMinsFromTicks = (ticks: number): string => {
  const seconds = ticks / 10000000 // Convert ticks to seconds
  const hours = Math.floor(seconds / 3600) // Get the full hours
  const minutes = Math.floor((seconds % 3600) / 60) // Get the full minutes
  // const remainingSeconds = Math.floor(seconds % 60) // Get the remaining seconds
  const hoursStr = hours > 0 ? `${hours}h ` : ''

  return `${hoursStr}${minutes}m`
}

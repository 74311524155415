import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'

interface PaginatorProps {
  page: number
  pageCount: number
  maxNumberOfPages: number
  getPaginationElem: (p: number) => JSX.Element
  onChange: (p: number) => void
}

const Paginator: React.FC<PaginatorProps> = ({ page, pageCount, maxNumberOfPages, getPaginationElem, onChange }) => {
  const [lowerRangePagination, setLowerRangePagination] = useState<Array<JSX.Element>>([])
  const [upperRangePagination, setUpperRangePagination] = useState<Array<JSX.Element>>([])
  const [middleRangePagination, setMiddleRangePagination] = useState<Array<JSX.Element>>([])

  useEffect(() => {
    let lowerRangeArr: Array<number>

    const lowerRangeSize: number = pageCount <= maxNumberOfPages ? pageCount : 3

    lowerRangeArr = new Array(lowerRangeSize).fill(0).map((_, index) => index + 1)
    const lowerRangePag = lowerRangeArr.map(p => getPaginationElem(p))

    let upperRangePag: Array<JSX.Element> = []
    let middleRangePag: Array<JSX.Element> = []
    if (pageCount > maxNumberOfPages) {
      const upperRangeArr = [pageCount - 2, pageCount - 1, pageCount]
      upperRangePag = upperRangeArr.map(p => getPaginationElem(p))

      if (!lowerRangeArr.some(l => l === page) && !upperRangeArr.some(u => u === page)) {
        // if the mid-range are immediately adjascent to the upper or lower range we just add them on there
        const lowerRangeUpperIndex = 3
        const upperRangeLowerIndex = pageCount - 2

        if (page === lowerRangeUpperIndex + 1 || page === lowerRangeUpperIndex + 2) {
          lowerRangePag.push(getPaginationElem(lowerRangeUpperIndex + 1))
          if (page === lowerRangeUpperIndex + 2) {
            lowerRangePag.push(getPaginationElem(lowerRangeUpperIndex + 2))
          }
        } else if (page === upperRangeLowerIndex - 1 || page === upperRangeLowerIndex - 2) {
          upperRangePag = [getPaginationElem(upperRangeLowerIndex - 1), ...upperRangePag]
          if (page === upperRangeLowerIndex - 2) {
            upperRangePag = [getPaginationElem(upperRangeLowerIndex - 2), ...upperRangePag]
          }
        } else {
          const middleRangeArr = [page - 1, page, page + 1]
          middleRangePag = middleRangeArr.map(p => getPaginationElem(p))
        }
      }
    }

    setLowerRangePagination(lowerRangePag)
    setUpperRangePagination(upperRangePag)
    setMiddleRangePagination(middleRangePag)
  }, [page, pageCount, maxNumberOfPages, getPaginationElem])

  return (
    <>
      <div className="hidden md:-mt-px md:flex">
        {lowerRangePagination && lowerRangePagination.map(lrp => lrp)}

        {middleRangePagination?.length > 0 && (
          <>
            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
              ...
            </span>
            {middleRangePagination.map(lrp => lrp)}
          </>
        )}
        {upperRangePagination?.length > 0 && (
          <>
            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
              ...
            </span>
            {upperRangePagination.map(lrp => lrp)}
          </>
        )}
      </div>
      <div className="md:hidden">
        <Listbox value={page} onChange={onChange}>
          <ListboxButton className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{page}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <FaChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>
          <ListboxOptions className="absolute w-[60px] max-h-[250px] overflow-y-auto border bg-white flex flex-col text-center">
            {new Array(pageCount)
              .fill(0)
              .map((_, index) => index + 1)
              .map(p => (
                <ListboxOption className="hover:bg-slate-300 cursor-pointer" key={p} value={p} disabled={false}>
                  {p}
                </ListboxOption>
              ))}
          </ListboxOptions>
        </Listbox>
      </div>
    </>
  )
}

export { Paginator }
export type { PaginatorProps }

// AuthContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { clearAuth, getAuth, setAuth } from '../lib/helpers/auth.helper'
import { TUserDto } from 'app-platform-shared-types'

type TAuthContextType = {
  isAuthenticated: boolean
  authUser?: TUserDto
  login: (authUserStr: string) => void
  logout: () => void
}

const AuthContext = createContext<TAuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    // Initialize state based on localStorage
    const authUser = getAuth()
    return authUser !== undefined // Convert to boolean
  })
  const [authUser, setAuthUser] = useState<TUserDto | undefined>(() => {
    return getAuth()
  })

  // Update localStorage whenever `authUser` changes
  useEffect(() => {
    authUser && setAuth(authUser)
  }, [authUser])

  const login = (authUserStr: string) => {
    const parsedAuthUser = JSON.parse(authUserStr)
    setIsAuthenticated(true)
    setAuthUser(parsedAuthUser)
  }
  const logout = () => {
    setIsAuthenticated(false)
    setAuthUser(undefined)
    clearAuth()
  }

  return <AuthContext.Provider value={{ isAuthenticated, authUser, login, logout }}>{children}</AuthContext.Provider>
}

export const useAuthContext = (): TAuthContextType => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider')
  }
  return context
}

import { tv, VariantProps } from 'tailwind-variants'
import MediaItemDetails from './_components/MediaItemDetails'
import { useQuery } from '@tanstack/react-query'
import { TApiResponse, TEmbyBaseItemDto } from 'app-platform-shared-types'
import { getMediaItem } from '../../../lib/api/emby-fetch'
import { useNavigate, useParams } from 'react-router-dom'

const EmbyItemTV = tv({
  slots: {
    base: 'relative w-full flex flex-row',
  },
  variants: {
    size: {
      sm: 'text-sm',
      md: 'text-md',
      lg: 'text-lg',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type EmbyItemVariants = VariantProps<typeof EmbyItemTV>

const fetchItem = async ({ queryKey }: any): Promise<TApiResponse<TEmbyBaseItemDto> | undefined> => {
  // console.log('queryKey', queryKey)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, { itemId }] = queryKey
  const result = await getMediaItem({ itemId }).then(r => {
    return r
  })

  return result
}

interface EmbyItemProps extends EmbyItemVariants {
  className?: string
  children?: React.ReactNode
}
const EmbyItem: React.FC<EmbyItemProps> = ({ className, children }) => {
  const navigate = useNavigate()
  const { itemId } = useParams<{ itemId: string }>()

  const useMediaItem = () =>
    useQuery({
      queryKey: ['mediaItem', { itemId }],
      queryFn: fetchItem,
    })

  const query = useMediaItem()

  const mediaItems: Array<TEmbyBaseItemDto> | undefined = query.data?.data
  const mediaItem = mediaItems?.length ? mediaItems[0] : undefined

  // console.log('EmbyItem: mediaItem', mediaItem)
  return (
    <>
      {mediaItem && (
        <>
          <MediaItemDetails item={mediaItem} onBack={() => navigate(-1)} />
        </>
      )}
    </>
  )
}

export default EmbyItem

import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from './providers'

const RoutesTokenInterceptor = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { login } = useAuthContext()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const error = queryParams.get('error')

    // console.log(token)
    // console.log(error)
    if (error) {
      navigate('/401', { state: { error: `${error}` } })
      return
    }

    const authUserToken = queryParams.get('authUser')

    if (authUserToken?.length) {
      login(authUserToken)
      queryParams.delete('authUser')
    }

    const callbackUrl = queryParams.get('callbackUrl')
    queryParams.delete('callbackUrl')

    const navigateUrl = callbackUrl?.length ? callbackUrl : '/'

    window.location.href = navigateUrl
  }, [location.search, navigate, login])

  return null
}

export default RoutesTokenInterceptor

interface EmbyLayoutProps {
  bannerTitleText: string
  children: React.ReactNode
}

const EmbyLayout: React.FC<EmbyLayoutProps> = (props: EmbyLayoutProps) => {
  return (
    <>
      {/* <div className="flex w-full justify-center items-center bg-accent text-white text-3xl">
        {props.bannerTitleText}
      </div> */}

      <div className="grid grid-cols-[1fr]">
        <div className="flex flex-wrap gap-2 w-full justify-center ">{props.children}</div>
      </div>
    </>
  )
}

export default EmbyLayout

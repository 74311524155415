import { FormEvent, Fragment, useRef, useState } from 'react'
import { TextArea, Button, LoadingOverlay, TextField, Dialog, DatePicker } from '../../../../../components'

import { FaRegCircle, FaRegDotCircle, FaTrash } from 'react-icons/fa'
import { DateTime } from 'luxon'
import ItemSelector, { TItemSelectorMeta } from '../../../../../components/core/ItemSelector'
import { useIsClient } from '../../../../../lib/hooks'
import { ClientConfig } from '../../../../../config/client.config'
import { Label, Radio, RadioGroup } from '@headlessui/react'
import PoolMemberCard from '../../_components/PoolMemberCard'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { addOrEditPoolActivity } from '../../../../../lib/api/pool-fetch'
import {
  TPoolActivityDto,
  TPoolMemberDto,
  TPoolActivityActionVerb,
  TPoolActivityType,
  TCmsAsset,
  TImageMeta,
  TPoolActivityPoolMember,
} from 'app-platform-shared-types'
import { useNavigate } from 'react-router-dom'

interface PoolActivityAddEditFormProps {
  poolId: string
  activity: TPoolActivityDto
  allPoolMembers: Array<TPoolMemberDto>
  action: TPoolActivityActionVerb
  onDone?: (activityId: string) => void
  onCancel?: () => void
}

const PoolActivityAddEditForm: React.FC<PoolActivityAddEditFormProps> = ({
  poolId,
  activity,
  allPoolMembers,
  action,
  onDone,
  onCancel,
}: PoolActivityAddEditFormProps) => {
  // state
  const { t } = useTranslation()
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    DateTime.fromISO(activity.targetPoolDateIso).toJSDate(),
  )
  const [note, setNote] = useState<string>(activity.note)
  const [selectedActivityType, setSelectedActivityType] = useState<TPoolActivityType>(activity.type ?? 'ACCOUNT_DEBIT')
  const [existingCmsImageAssets, setExistingCmsImageAssets] = useState<Array<TCmsAsset>>(
    activity.cmsAsset?.cmsAssetsMeta ?? [],
  )
  const [creditDebitAmt, setCreditDebitAmt] = useState<number | undefined>(activity.creditDebitAmt)
  const [existingCmsImageAssetsToRemove, setExistingCmsImageAssetsToRemove] = useState<Array<TCmsAsset>>([])
  const [newCmsImageAssets, setNewCmsImageAssets] = useState<Array<TImageMeta>>([])
  const [selectedPoolMembers, setSelectedPoolMembers] = useState<Array<TPoolMemberDto>>(
    activity.poolMembers?.map(pm => pm.poolMember) ?? [],
  )
  const isClientReady = useIsClient()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showConfigmDialog, setShowConfirmDialog] = useState<boolean>(false)

  const navigate = useNavigate()

  const fileInputRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const handleSelectFilesClick = () => {
    fileInputRef?.current?.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: Array<File> | undefined = event.target.files ? Array.from(event.target.files) : undefined
    // console.log('handleFileChange:files', files)
    if (files?.length) {
      // setSelectedFiles(files)
      const newThumbnails: Array<TImageMeta> = []

      files.forEach(file => {
        const reader = new FileReader()
        reader.onloadend = () => {
          newThumbnails.push({ fileData: reader.result as string })
          if (newThumbnails.length === files.length) {
            setNewCmsImageAssets([...newCmsImageAssets, ...newThumbnails])
          }
        }
        reader.readAsDataURL(file)
      })
    }
  }

  const formReset = () => {
    setNewCmsImageAssets([])
    setSelectedDate(undefined)
    formRef.current && formRef.current.reset()
  }

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    try {
      // TODO: validate form
      // console.log('formData:formRef.current', formRef.current)
      // console.log('formData:formRef.current.checkValidity', formRef.current?.checkValidity())
      const formData = new FormData(formRef.current ?? undefined) // Using FormData to retrieve form values

      // console.log('formData', formData)
      const jsonData: any = {
        files: [],
        activityPoolMemberIds: new Array<TPoolActivityPoolMember>(),
        cmsAssetsIdsToRemove: new Array<number>(),
      }
      formData.forEach((value, key) => {
        if (key !== 'files') {
          jsonData[`${key}`] = value
        }
      })

      newCmsImageAssets.forEach(tn => {
        jsonData['files'].push({
          fileData: tn.fileData,
        })
      })

      selectedPoolMembers.forEach(spm =>
        jsonData.activityPoolMemberIds.push({ poolActivityId: activity.id, poolMemberId: spm.id }),
      )

      existingCmsImageAssetsToRemove.forEach(eci => jsonData.cmsAssetsIdsToRemove.push(eci.id))

      // console.log('jsonData', jsonData)
      // console.log('jsonData.Stringified', JSON.stringify(jsonData))
      const updtActivity = await addOrEditPoolActivity(poolId, activity.id, JSON.stringify(jsonData))

      if (updtActivity) {
        formReset()
        onDone && onDone(updtActivity.id)
      } else {
        console.error(`Error creating pool activity`, updtActivity)
        alert('An error occurred while trying to add a pool activity.')
        setIsSubmitting(false)
      }
    } catch (err) {
      setIsSubmitting(false)
      console.error(err)
      alert('An unknown error ocurred.')
      navigate(-1)
    }
  }

  const activityTypes: Array<TPoolActivityType> = ['ACCOUNT_DEBIT', 'ACCOUNT_CREDIT']
  const activityTypeOptions = activityTypes.map(ato => {
    let langTerm
    switch (ato) {
      case 'ACCOUNT_CREDIT':
        langTerm = t('pool.activity.accountCredit')
        break

      case 'ACCOUNT_DEBIT':
        langTerm = t('pool.activity.accountDebit')
        break

      default:
        langTerm = 'UNKNOWN_OPTION'
        break
    }
    return {
      key: ato,
      text: langTerm,
      selected: activity.type === ato,
    }
  })

  const itemSelectorMeta: TItemSelectorMeta = {
    selectedItemsLabel: t('pool.activity.selectedPoolMembersLabel'),
    selectedLabelClassName: 'text-lg font-bold text-shadow',
    selectedContainerClassName: 'w-full flex flex-wrap justify-center rounded border border-gray-500 m-2 p-1',
    unselectedItemsLabel: t('pool.activity.unselectedPoolMembersLabel'),
    unselectedLabelClassName: 'text-lg font-bold text-highlight',
    unselectedContainerClassName: 'w-full flex flex-wrap justify-center rounded border border-gray-500 m-2 p-1',
    atLeastOneItemLabel: t('pool.activity.activityAtLeastOnePoolMemberLabel'),
    allItemsSelectedLabel: t('pool.activity.activityAllMembersSelected'),
  }

  // console.log('existing and remove assets {existingCmsImageAssets,existingCmsImageAssetsToRemove}', {
  //   existingCmsImageAssets,
  //   existingCmsImageAssetsToRemove,
  // })
  // console.log('activity.cmsAsset', activity.cmsAsset)

  const isEdit = action === 'edit'
  return (
    <>
      <LoadingOverlay show={isSubmitting} text={'Saving...'} />
      <Dialog
        isOpen={showConfigmDialog}
        dialogTitle={t('common.confirm')}
        actionButtonClick={async () => {
          setShowConfirmDialog(false)
          setIsSubmitting(true)
          await handleSubmit()
        }}
        cancelButtonClick={() => setShowConfirmDialog(false)}
      >
        {t('pool.activity.confirmDebitCredit')}
      </Dialog>
      <form
        ref={formRef}
        // onSubmit={(e: FormEvent<HTMLFormElement>) => {
        //   setIsSubmitting(true)
        //   handleSubmit(e)
        // }}
        className="w-[325px] md:w-[550px] lg:w-[750px] h-full "
      >
        <div className="w-full h-full flex flex-col justify-between gap-1">
          <div className="w-full border flex flex-col items-center gap-2 border-b-gray-300 py-1">
            <RadioGroup
              name="activityType"
              disabled={isEdit}
              value={selectedActivityType}
              onChange={(val: TPoolActivityType) => setSelectedActivityType(val)}
            >
              <Label>{t('pool.activity.type')}</Label>
              <div className="flex flex-row">
                {activityTypeOptions.map(ato => (
                  /* Use the `active` state to conditionally style the active option. */
                  /* Use the `checked` state to conditionally style the checked option. */
                  <Radio key={ato.key} value={ato.key} as={Fragment}>
                    {({ disabled, checked }) => (
                      <li
                        className={clsx(
                          disabled ? 'cursor-not-allowed text-highlight text-opacity-50' : 'cursor-pointer',
                          'list-none flex flex-row items-center gap-2 m-2',
                          // `${active ? 'bg-blue-500 text-white' : 'bg-white text-black'}`,
                        )}
                      >
                        {checked ? (
                          <FaRegDotCircle className="text-accent" />
                        ) : (
                          <FaRegCircle className="text-highlight" />
                        )}
                        {ato.text}
                      </li>
                    )}
                  </Radio>
                ))}
              </div>
            </RadioGroup>
            <TextField
              className={clsx('max-w-[125px]', isEdit && 'cursor-not-allowed opacity-50')}
              disabled={isEdit}
              label={`${t('pool.activity.activityCreditDebitAmt')}`}
              id="creditDebitAmt"
              name="creditDebitAmt"
              type="number"
              isRequired
              value={creditDebitAmt?.toString()}
              onChange={(val: string) => {
                try {
                  const valNum = parseFloat(val)
                  setCreditDebitAmt(valNum)
                } catch (error) {
                  console.error(error)
                  throw new Error('Invalid value entered for credit debit amt')
                }
              }}
              // isInvalid={isInvalid(formPristine, firstName)}
              // errorMessage={isInvalid(formPristine, firstName) ? t('contact.client.firstNameReq') : undefined}
            />
            <TextArea
              className="min-w-[300px]"
              label={t('pool.activity.note')}
              labelClassName="font-bold text-sm"
              id="note"
              name="note"
              type="text"
              value={note ?? ''}
              onChange={setNote}
              rows={6}
            ></TextArea>
            <span>{t('pool.activity.effectiveDate')}</span>
            <DatePicker
              selectedDate={selectedDate}
              onChangeFn={(val: Date) => {
                setSelectedDate(val)
              }}
            />
            <input type="hidden" value={activity.id ?? ''} id="id" name="id" />
            <input type="hidden" value={activity.cmsAsset?.id ?? ''} id="id" name="cmsPoolAssetId" />
            <input
              type="hidden"
              value={selectedDate ? DateTime.fromJSDate(selectedDate).toISO() ?? undefined : ''}
              id="selectedDate"
              name="selectedDate"
            />
            <div>Activity Member(s)</div>
            <ItemSelector<TPoolMemberDto>
              isEdit={action === 'add'}
              selectedItems={selectedPoolMembers}
              allItems={allPoolMembers}
              onChange={(poolMembers: Array<TPoolMemberDto>) => setSelectedPoolMembers(poolMembers)}
              meta={itemSelectorMeta}
              allJsxNode={allPoolMembers.map(pm => {
                return {
                  id: pm.id,
                  reactNode: (
                    <div key={pm.id} className="w-[140px]">
                      <PoolMemberCard poolMember={pm} hideBalance={true} />
                    </div>
                  ),
                }
              })}
            />

            <input type="hidden" value={activity?.cmsAsset?.id ?? ''} id="assetId" name="assetId" />
            <Button className="p-2" onPress={handleSelectFilesClick} theme="secondary">
              Select Files
            </Button>
            <input
              className="hidden"
              id="files"
              name="files"
              type="file"
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            {existingCmsImageAssets?.length > 0 ? (
              <div className="w-full p-1">
                {existingCmsImageAssets.map((a: TCmsAsset, index: number) => {
                  const assetMeta = a.cmsAssetMetaFormats.thumbnail
                  if (!assetMeta) {
                    throw new Error('MISSING CMS THUMBNAIL')
                  }
                  return (
                    <div key={a.id} className="flex flex-row items-center justify-between p-2">
                      <img
                        src={`${ClientConfig.publicCmsUrl}${assetMeta.url}`}
                        alt={assetMeta.name}
                        height={30}
                        width={30}
                        className="h-[30px] w-[30px]"
                      />
                      <span className="flex flex-row items-center gap-2">
                        <FaTrash
                          className="cursor-pointer"
                          onClick={() => {
                            const removedImageAsset = existingCmsImageAssets.splice(index, 1)
                            setExistingCmsImageAssetsToRemove([...existingCmsImageAssetsToRemove, removedImageAsset[0]])
                            setExistingCmsImageAssets([...existingCmsImageAssets])
                          }}
                        />
                      </span>
                    </div>
                  )
                })}
              </div>
            ) : (
              'No Assets'
            )}
            {newCmsImageAssets?.length > 0 && (
              <div className="w-full p-1">
                {newCmsImageAssets.map((thumbnail, index) => (
                  <div key={index} className="flex flex-row items-center justify-between p-2">
                    <img
                      src={thumbnail.fileData}
                      alt="Thumbnail"
                      width={30}
                      height={30}
                      className="h-[30px] w-[30px]"
                    />
                    <span className="flex flex-row items-center gap-2">
                      <FaTrash
                        className="cursor-pointer"
                        onClick={() => {
                          newCmsImageAssets.splice(index, 1)
                          // console.log('newCmsImageAssets', newCmsImageAssets)
                          setNewCmsImageAssets([...newCmsImageAssets])
                        }}
                      />
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            <div className="flex flex-row items-end justify-end gap-1 m-2">
              <Button
                className="p-2"
                onPress={() => {
                  formReset()
                  onCancel && onCancel()
                }}
                theme="secondary"
              >
                Cancel
              </Button>
              <Button
                className="p-2"
                onPress={async () => {
                  if (isEdit) {
                    setIsSubmitting(true)
                    await handleSubmit()
                  } else {
                    setShowConfirmDialog(true)
                  }
                }}
                theme="primary"
                disabled={!isClientReady || isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default PoolActivityAddEditForm

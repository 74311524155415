import { TPerson } from 'app-platform-shared-types'
import { PressEvent, usePress } from 'react-aria'
import { tv, VariantProps } from 'tailwind-variants'
import { getItemImgWithETag } from '../../../../lib/helpers/emby.helper'
import { FaQuestion } from 'react-icons/fa6'
import clsx from 'clsx'

const PersonCardTV = tv({
  slots: {
    base: 'relative w-full h-full flex flex-col gap-1 justify-between',
    text: 'text-shadow text-center truncate',
    image: 'rounded-sm w-full',
  },
  variants: {
    size: {
      xs: { text: 'text-xs' },
      sm: { text: 'text-sm' },
      md: { text: 'text-md' },
      lg: { text: 'text-lg' },
      xl: { text: 'text-xl' },
      xxl: { text: 'text-2xl' },
    },
  },
  defaultVariants: {
    size: 'xs',
  },
})

type PersonCardVariants = VariantProps<typeof PersonCardTV>

interface PersonCardProps extends PersonCardVariants {
  person: TPerson
  onPress?: (e: PressEvent) => void
  className?: string
  children?: React.ReactNode
}

const PersonCard: React.FC<PersonCardProps> = ({ className, children, ...props }) => {
  const { base, image, text } = PersonCardTV(props)

  // console.log('Person Card props.size', props.size)
  const { pressProps: personPressProps } = usePress({
    onPress: (e: PressEvent) => props.onPress && props.onPress(e),
  })
  return (
    <div key={props.person.Id} className={clsx(base(props), props.onPress && 'cursor-pointer')} {...personPressProps}>
      <div className="flex-grow">
        {props.person.PrimaryImageTag?.length ? (
          <img
            className={image(props)}
            src={getItemImgWithETag(props.person.Id, props.person.PrimaryImageTag, 90, 'Primary')}
            alt={`${props.person.Name}`}
          />
        ) : (
          <div className="relative w-full h-full">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <FaQuestion className="text-6xl opacity-50 font-bold " />
            </div>
          </div>
        )}
      </div>
      <div className="w-full  flex flex-col justify-center font-semibold align-top">
        <span className={text(props)}>{`${props.person.Name}`}</span>
        <span className={text(props)}>{`${props.person.Role}`}</span>
      </div>
    </div>
  )
}

export default PersonCard

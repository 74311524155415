import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { orderBy } from 'lodash'
import { getPoolActivityLocaleText } from '../../../lib/helpers/pool.helper'
import { FaPlus } from 'react-icons/fa'
import { FaBan, FaMagnifyingGlass, FaPencil, FaTrash } from 'react-icons/fa6'
import { NIL as EMPTY_GUID } from 'uuid'
import PoolMemberCard from '../_components/PoolMemberCard'
import { useTranslation } from 'react-i18next'
import { Avatar, Button, Dialog, LoadingOverlay, Paginate } from '../../../components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PoolLayout from '../../../layouts/PoolLayout'
import { deletePool, getPool, getPoolActivities } from '../../../lib/api/pool-fetch'
import { TPoolActionVerb, TPoolActivityDto, TPoolDto, TPoolMemberDto } from 'app-platform-shared-types'
import PoolAddEditForm from './_components/PoolUpsertForm'

interface PoolProps {}

type TPoolActivitiesMeta = {
  activities: Array<TPoolActivityDto>
  totalCount: number
}

const getPoolActivitiesForGrid = async (
  poolId: string,
  page: number,
  pageSize: number,
): Promise<TPoolActivitiesMeta | undefined> => {
  return getPoolActivities(poolId, page * pageSize, pageSize).then(pa => {
    return pa
      ? ({
          activities: pa?.data ?? [],
          totalCount: pa.meta?.pagination?.total ?? 0,
        } as TPoolActivitiesMeta)
      : undefined
  })
}

const toggleActivityGrid = async (
  poolId: string,
  page: number,
  pageSize: number,
): Promise<TPoolActivitiesMeta | undefined | undefined> => {
  return getPoolActivitiesForGrid(poolId, page, pageSize)
}

const Pool: React.FC<PoolProps> = () => {
  const { poolId } = useParams<{ poolId: string }>()
  const { action: actionParam } = useParams<{ action?: TPoolActionVerb }>()

  const [action] = useState<TPoolActionVerb>(actionParam ?? 'view')

  if (!poolId) {
    throw new Error('Invalid access to Pool')
  }
  const PAGE_SIZE = 10
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [poolLoading, setPoolLoading] = useState<boolean>(true)
  const [poolDeleting, setPoolDeleting] = useState<boolean>(false)
  const [poolActivitiesLoading, setPoolActivitiesLoading] = useState<boolean>(true)
  const [pageTitle] = useState<string>(t('pool.titlePool'))
  const [activityTake] = useState<number>(PAGE_SIZE)
  const [pool, setPool] = useState<TPoolDto | undefined>()
  const [activities, setActivities] = useState<Array<TPoolActivityDto>>([])
  const [totalNumActivities, setTotalNumActivities] = useState<number>(0)
  const [sortedPms, setSortedPms] = useState<Array<TPoolMemberDto>>([])
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [poolOwner, setPoolOwner] = useState<TPoolMemberDto | undefined>(undefined)

  useEffect(() => {
    getPool(poolId).then(pool => {
      if (!pool) {
        throw new Error('Invalid pool')
      }
      setPool(pool)
      setSortedPms(
        orderBy(
          pool.poolMembers.filter(pm => !pm.isRemoved),
          ['displayName'],
        ),
      )
      setPoolLoading(false)
      setPoolOwner(pool.poolMembers.find(pm => pm.userId === pool.userId))
    })

    poolId &&
      getPoolActivitiesForGrid(poolId, 0, activityTake).then(result => {
        setActivities(result?.activities ?? [])
        setTotalNumActivities(result?.totalCount ?? 0)
        setPoolActivitiesLoading(false)
      })
  }, [activityTake, poolId])

  const handlePageClick = (pageNumber: number) => {
    // const pageNum = parseInt(event.selected, 10)
    if (!isNaN(pageNumber)) {
      toggleActivityGrid(poolId, pageNumber, activityTake).then(result => {
        setActivities(result?.activities ?? [])
        setTotalNumActivities(result?.totalCount ?? 0)
      })
    }
  }

  const handleDeletePool = async () => {
    setShowConfirmDelete(false)
    setPoolDeleting(true)
    try {
      await deletePool(poolId).then(() => navigate('/pools'))
    } catch (err) {
      console.error('Pool.tsx delete error', err)
      alert(t('pool.deletePoolError'))
      setPoolDeleting(false)
    }
  }

  if (action !== 'view' && action !== 'edit' && action !== 'add') {
    navigate('/404')
  }

  return (
    <>
      <LoadingOverlay text={t('common.loading')} show={poolLoading || poolActivitiesLoading || poolDeleting} />
      {action === 'view' && (
        <PoolLayout bannerTitleText={pageTitle}>
          <div className="flex items-center gap-2 mt-1">
            <div className="text-2xl ">{pool?.name ?? ''}</div>
            {pool?.isPoolOwner && (
              <Link to={'edit'}>
                <Button
                  theme="primary"
                  className="justify-center items-center rounded-full p-2 text-xl"
                  title={t('common.edit')}
                >
                  <FaPencil />
                </Button>
              </Link>
            )}
          </div>
          <div className="flex flex-col w-full justify-center items-center">
            <Avatar className="h-16 w-16" imageUrl={poolOwner?.imgUrl}></Avatar>
            <span>{t('pool.owner')}</span>
            {pool?.isPoolOwner && (
              <span className="bg-highlight text-gray-700 rounded-full p-3">${pool?.balance ?? 0}</span>
            )}
          </div>
          {/** pool settings */}
          {/* <div className="w-full flex flex-col justify-center items-center">
          {pool && <PoolSettings pool={pool} poolSettings={pool.poolSettings[0]} />}
        </div> */}
          {/** pool members */}
          <div className="w-full flex flex-col flex-wrap p-1 ">
            <div className="text-xl text-center">{t('pool.poolMembers')}</div>
            <div className="flex flex-wrap m-1 justify-center">
              {pool &&
                sortedPms.map((pm, i) => (
                  <span key={i} className="w-full max-w-[175px]">
                    <PoolMemberCard poolMember={pm} />
                  </span>
                ))}
            </div>
          </div>
          {/** activities */}
          <div className="w-full">
            <div className="text-xl text-center">{t('pool.activities')}</div>
            <div className="font-semibold grid grid-cols-[2fr,3fr,3fr,3fr] gap-3 border-shadow border-b p-1">
              <div className="flex flex-col justify-center">
                <span>{t('pool.activity.type')}</span>
              </div>
              <div className="flex flex-col justify-center">
                <span>{t('pool.activity.note')}</span>
              </div>
              <div className="flex flex-col justify-center">
                <span>{t('common.date')}</span>
              </div>
              {/* <div className="flex flex-col justify-center">
            <span>{t('pool.activity').activityTransactions}</span>
          </div> */}
              {pool?.isPoolOwner && (
                <div className="flex flex-row items-center justify-end text-xl">
                  <Link to={`activity/${EMPTY_GUID}/add`}>
                    <Button theme="primary" className="justify-center items-center rounded-full p-2 text-xl">
                      <FaPlus />
                    </Button>
                  </Link>
                </div>
              )}
            </div>
            {activities?.length ? (
              activities?.map((pa, index) => {
                return (
                  <div key={index} className="grid grid-cols-[2fr,3fr,3fr,3fr] w-full border-y p-1 border-highlight">
                    <div className="p-1">{getPoolActivityLocaleText(pa.type)}</div>
                    <div className="p-1">{pa.note}</div>
                    <div className="p-1">
                      {pa.targetPoolDateIso?.length
                        ? DateTime.fromISO(pa.lastUpdatedIso).toLocaleString(DateTime.DATETIME_SHORT)
                        : '-'}
                    </div>
                    {/* <div>{pa.transactions.length}</div> */}
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Link to={`activity/${pa.id}`}>
                        <Button theme="secondary" className="rounded-full justify-center items-center text-xl p-2">
                          <FaMagnifyingGlass />
                        </Button>
                      </Link>
                      {pool?.isPoolOwner && (
                        <>
                          <Link to={`activity/${pa.id}/edit`}>
                            <Button theme="secondary" className="rounded-full justify-center items-center text-xl p-2">
                              <FaPencil />
                            </Button>
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="w-full flex justify-center ">{t('pool.noTransactions')}</div>
            )}
          </div>
          {pool && totalNumActivities > PAGE_SIZE && (
            <>
              <div className="w-full flex justify-center my-1">
                <Paginate
                  // forcePage={0}
                  pageCount={Math.ceil(totalNumActivities / PAGE_SIZE)}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          )}
          {pool?.isPoolOwner && (
            <div className="mt-5">
              <Button
                theme="danger"
                className="rounded-md justify-center items-center text-xl p-2"
                onPress={() => setShowConfirmDelete(true)}
              >
                <FaTrash />
                &nbsp;{t('pool.deletePool')}
              </Button>
              <Dialog isOpen={showConfirmDelete} className="min-w-[75%]">
                <>
                  <div className="text-lg my-2">{t('pool.confirmDeletePool')}</div>
                  <div className="flex justify-between mt-6 border-t pt-2">
                    <Button
                      theme="neutral"
                      className="rounded-md justify-center items-center text-xl p-2"
                      onPress={() => setShowConfirmDelete(false)}
                    >
                      <FaBan />
                      &nbsp;{t('common.cancel')}
                    </Button>
                    <Button
                      theme="danger"
                      className="rounded-md justify-center items-center text-xl p-2"
                      onPress={() => handleDeletePool()}
                    >
                      <FaTrash />
                      &nbsp;{t('pool.deletePool')}
                    </Button>
                  </div>
                </>
              </Dialog>
            </div>
          )}
        </PoolLayout>
      )}
      {pool && (action === 'add' || action === 'edit') && (
        <PoolLayout bannerTitleText={pageTitle}>
          <PoolAddEditForm
            setPoolLoading={setPoolLoading}
            pool={pool}
            onCancel={() => navigate(-1)}
            // onSubmit={(updtPool: TPoolDto | undefined) => {
            //   // setPoolLoading(false)
            //   // setPool(updtPool)
            //   // setAction('view')
            //   console.log('Pool.tsx on Submit', updtPool)
            //   alert('Pool.tsx on Submit')
            // }}
            // onSubmit={async pool => await handlePoolUpdate(pool)}
          ></PoolAddEditForm>
        </PoolLayout>
      )}
    </>
  )
}

export default Pool

import { TEmbyBaseItemDto } from 'app-platform-shared-types'
import MediaItemBanner from './MediaItemBanner'
import { getBackdropUrls } from '../../../../lib/helpers/emby.helper'
import Carousel from '../../../../components/core/Carousel'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../../../providers'
import PersonCard from './PersonCard'
import { BackButton } from '../../../../components'
import MediaItem from './MediaItem'

interface MediaItemDetailsProps {
  item: TEmbyBaseItemDto
  onBack?: () => void
}

const MediaItemDetails: React.FC<MediaItemDetailsProps> = props => {
  const { t } = useTranslation()
  const item = props.item
  const { zoomSize } = useAppContext()

  const backdropImgUrls = getBackdropUrls(item)
  const backdropImg = backdropImgUrls?.length > 0 ? backdropImgUrls[0] : ''

  const seasons = props.item.Children.filter(c => (c.Type = 'Season'))

  return (
    <>
      <div className="relative">
        {backdropImg?.length > 0 && (
          <>
            <img src={backdropImg} className="-z-20 fixed top-0 left-0 h-screen w-screen opacity-60" alt="Backdrop" />
            <div className="-z-10 fixed top-0 left-0 h-screen w-screen opacity-80 bg-gradient-to-r from-shadow to-[#9fa1a3]" />
          </>
        )}
      </div>
      <div className="flex flex-col overflow-y-auto scrollbar-hide">
        <div className="w-full">
          <BackButton />
        </div>

        <MediaItemBanner item={props.item} size={zoomSize} />
        {seasons.length > 0 ? (
          <div className="w-full flex flex-col">
            <div className="text-2xl text-white m-2">{t('emby.seasons')}</div>
            <Carousel itemSize={zoomSize}>
              {seasons.map((season, index) => (
                <MediaItem mediaItem={season} key={index} size={zoomSize} />
              ))}
            </Carousel>
          </div>
        ) : null}
        {props.item.People?.length > 0 ? (
          <div className="w-full flex flex-col">
            <div className="text-2xl text-white m-2">{t('emby.castAndCrew')}</div>
            <Carousel itemSize={zoomSize}>
              {props.item.People.map((person, index) => (
                <PersonCard person={person} key={index} size={zoomSize} />
              ))}
            </Carousel>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default MediaItemDetails

import React, { useEffect } from 'react'
import { TEmbyBaseItemDto } from 'app-platform-shared-types'
import clsx from 'clsx'
import { useState } from 'react'
import { PressEvent, useHover, usePress } from 'react-aria'
import { tv, VariantProps } from 'tailwind-variants'
import { Blurhash } from 'react-blurhash'
import { getItemImgWithETag } from '../../../../lib/helpers/emby.helper'

const mediaItemTV = tv({
  slots: {
    base: 'relative flex flex-row gap-1 cursor-pointer ',
    tile: 'flex flex-col justify-center  text-wrap w-[200px]',
    tileText: 'flex text-xs font-bold text-shadow truncate first-line:overflow-ellipsis mb-1',
    tileHover: 'shadow-lg border border-shadow border-opacity-50 rounded-b-md',
    // overlay: 'absolute h-10 w-10 bg-white',
    image: 'w-full h-full flex flex-col justify-center relative',
  },
  variants: {
    size: {
      xs: {
        tile: 'w-[125px] h-[211px]',
      },
      sm: {
        tile: 'w-[150px]  h-[249px]',
      },
      md: {
        tile: 'w-[175px] h-[286px]',
      },
      lg: {
        tile: 'w-[200px]  h-[324px]',
      },
      xl: {
        tile: 'w-[225px]  h-[377px]',
        tileText: 'text-xl',
      },
      xxl: {
        tile: 'text-xl w-[250px] h-[415px]',
        tileText: 'text-xl',
      },
    },
    textSize: {
      xs: {
        base: 'text-sm',
      },
      sm: {
        base: 'text-sm',
      },
      md: {
        base: 'text-md',
      },
      lg: {
        base: 'text-lg',
      },
      xl: {
        base: 'text-xl',
      },
      xxl: {
        base: 'text-2xl',
      },
    },
    theme: {
      primary: {
        base: 'text-accent ',
      },
      secondary: {
        base: 'text-midtone ',
      },
      neutral: {
        base: 'text-shadow',
      },
      danger: {
        base: 'text-red-600',
      },
    },
  },
  defaultVariants: {
    theme: 'neutral',
  },
})

type MediaItemVariants = VariantProps<typeof mediaItemTV>
interface MediaItemProps extends MediaItemVariants {
  mediaItem: TEmbyBaseItemDto
  rootElemClassName?: string
  onPress?: (e: TEmbyBaseItemDto) => void
  onHoverChange?: (isHovering: boolean) => void
}

const MediaItem: React.FC<MediaItemProps> = (props: MediaItemProps) => {
  const { base, tile, tileText, tileHover, image } = mediaItemTV(props)
  const [tileImgLoaded, setTileImgLoaded] = useState<boolean>(false)
  const [imgUrl, setImgUrl] = useState<string>('')

  useEffect(() => {
    setImgUrl(getItemImgWithETag(props.mediaItem.Id, props.mediaItem.ImageTags.Primary, 90, 'Primary'))
  }, [props.mediaItem])

  // const buttonRef = useRef<HTMLButtonElement | null>(null)
  // const { buttonProps } = useButton(props, buttonRef)

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const { hoverProps } = useHover({
    onHoverChange: setIsHovered,
  })

  const { pressProps } = usePress({
    onPress: (e: PressEvent) => props.onPress && props.onPress(props.mediaItem),
  })

  return (
    <>
      <div
        className={clsx(base(props), tile(props), isHovered && tileHover(props), props.rootElemClassName)}
        title={props.mediaItem.Name}
        // {...buttonProps}
        {...hoverProps}
        {...pressProps}
      >
        <div className={image(props)}>
          {!tileImgLoaded && (
            <div
              className={clsx(
                'w-full h-full',
                'bg-gradient-to-r from-shadow via-[#767ba3]  to-highlight',
                'bg-[length:200%_200%] animate-gradient-loading rounded-md',
              )}
            ></div>
            // <Blurhash className={clsx('absolute')} hash="L7ECRV9ZNyIoVp4mWBxu00~pWCs:" width={'100%'} height={'100%'} />
          )}
          <div className={clsx({ hidden: !tileImgLoaded }, 'w-full absolute z-10', tileImgLoaded ? '' : '')}>
            <img onLoad={() => setTileImgLoaded(true)} src={imgUrl} alt="Media item" className="w-full h-full" />
          </div>
        </div>

        <div className={tileText(props)}>
          <span>{props.mediaItem.Name}</span>
        </div>
      </div>
    </>
  )
}

export default MediaItem

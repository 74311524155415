import { SetStateAction, useEffect, useState } from 'react'
import { Avatar, Button, Dialog, LoadingOverlay, Paginate } from '../../../components'
import AdminLayout from '../../../layouts/AdminLayout'
import { useTranslation } from 'react-i18next'
import { TApiResponseMeta, TUserDto } from 'app-platform-shared-types'
import { getUsers, postUser } from '../../../lib/api/user-admin.fetch'
import { FaPencil, FaPlus } from 'react-icons/fa6'
import UserForm from './UserForm'
import { NIL as EMPTY_GUID } from 'uuid'

interface UserAdminProps {}

const getAdminUserAndUpdateState = (
  skip: number,
  take: number,
  setUsers: (value: SetStateAction<TUserDto[]>) => void,
  setUsersMeta: (value: SetStateAction<TApiResponseMeta | undefined>) => void,
  setUsersLoading: (value: SetStateAction<boolean>) => void,
) => {
  setUsersLoading(true)
  getUsers(skip, take).then(us => {
    setUsers(us?.data ?? [])
    setUsersMeta(us?.meta)
    setUsersLoading(false)
  })
}

const UserAdmin: React.FC<UserAdminProps> = () => {
  const [t] = useTranslation()
  const [usersLoading, setUsersLoading] = useState<boolean>(true)
  const [user, setUser] = useState<TUserDto | undefined>(undefined)
  const [users, setUsers] = useState<Array<TUserDto>>([])
  const [usersMeta, setUsersMeta] = useState<TApiResponseMeta | undefined>(undefined)
  const [showUserDialog, setShowUserDialog] = useState<boolean>(false)
  const [pageSize] = useState<number>(10)

  useEffect(() => {
    getAdminUserAndUpdateState(0, pageSize, setUsers, setUsersMeta, setUsersLoading)
  }, [pageSize])

  const submitUserForm = async (user: TUserDto): Promise<void> => {
    setShowUserDialog(false)
    setUsersLoading(true)
    try {
      const updtUser = await postUser(user)
      if (!updtUser || updtUser?.data?.length === 0) {
        throw new Error('Error posting user data')
      }
    } catch (err) {
      console.error(err)
      window.alert('An error occurred while trying to update the user')
      setUsersLoading(false)
    }
    getAdminUserAndUpdateState(0, pageSize, setUsers, setUsersMeta, setUsersLoading)
    return
  }

  return (
    <>
      <AdminLayout>
        <LoadingOverlay show={usersLoading} text={t('common.loading')} />
        <Dialog isOpen={showUserDialog} className="w-[350px]">
          {user && <UserForm onSubmit={submitUserForm} onCancel={() => setShowUserDialog(false)} user={{ ...user }} />}
        </Dialog>
        <div className="flex flex-col items-center">
          {users && users.length > 0 && (
            <div className="flex flex-col items-center mt-4">
              <span>{t('user.users')}</span>
              {/* <TextField placeholder="search" label="Search Emails" /> */}
              <div className="w-full flex justify-center my-1">
                <Paginate
                  // forcePage={usersMeta?.pagination.page ?? 0}
                  pageCount={usersMeta?.pagination.pageCount ?? 0}
                  onPageChange={(page: number): void => {
                    getAdminUserAndUpdateState(page * pageSize, pageSize, setUsers, setUsersMeta, setUsersLoading)
                  }}
                />
              </div>
              <div className="w-full grid grid-cols-[2fr_4fr_4fr_2fr] gap-1 border-b mt-2 font-bold">
                <div></div>
                <div>{t('user.displayName')}</div>
                <div>{t('user.providerUsername')}</div>
              </div>
              {users.map(u => (
                <div key={u.id} className="w-full grid grid-cols-[2fr_4fr_4fr_2fr] gap-1 border-b py-2">
                  <div>
                    <Avatar imageUrl={u.imgUrl} size="xs" className="rounded-full" />
                  </div>
                  <div>{u.displayName}</div>
                  <div>{u.providerUsername}</div>
                  <div
                    className="flex justify-center items-center cursor-pointer"
                    onClick={() => {
                      setUser(u)
                      setShowUserDialog(true)
                    }}
                  >
                    <FaPencil />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="m-1">
            <Button
              theme="primary"
              className="p-2"
              onPress={() => {
                setUser({ id: EMPTY_GUID } as TUserDto)
                setShowUserDialog(true)
              }}
            >
              <FaPlus />
              &nbsp;
              {t('user.createUser')}
            </Button>
          </div>
        </div>
      </AdminLayout>
    </>
  )
}

export default UserAdmin

import { TZoomSizes } from 'app-platform-shared-types'
import clsx from 'clsx'
import { usePress } from 'react-aria'
import { GoDotFill, GoDot } from 'react-icons/go'
import { MdOutlineZoomOut, MdOutlineZoomIn } from 'react-icons/md'
import { tv, VariantProps } from 'tailwind-variants'

const ZoomSelectorTV = tv({
  slots: {
    base: 'relative flex flex-row justify-center gap-1 w-full',
  },
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-md',
      lg: 'text-lg',
      xl: 'text-xl',
      xxl: 'text-2xl',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type ZoomSelectorVariants = VariantProps<typeof ZoomSelectorTV>

interface ZoomSelectorProps extends ZoomSelectorVariants {
  initialSize: TZoomSizes
  className?: string
  onZoomChange: (zoomSize: TZoomSizes) => void
}

const availableSizes: Array<TZoomSizes> = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']

const ZoomSelector: React.FC<ZoomSelectorProps> = props => {
  // const [size, setSize] = useState<TZoomSizes>(props.initialSize)

  const getNextZoomLevel = (increment: 1 | -1) => {
    const selectedSizeIndex = availableSizes.findIndex(as => as === props.size)
    const newIndex = (selectedSizeIndex + increment + availableSizes.length) % availableSizes.length

    const newSize = availableSizes[newIndex]
    props.onZoomChange(newSize)
  }

  const { pressProps: zoomDownPressProps } = usePress({
    onPress: () => getNextZoomLevel(-1),
  })
  const { pressProps: zoomUpPressProps } = usePress({
    onPress: () => getNextZoomLevel(1),
  })

  const { base } = ZoomSelectorTV(props)
  return (
    <>
      <div className={base(props)}>
        <div className="h-12 w-6 relative">
          <MdOutlineZoomOut
            className="scale-x-[-1] absolute inset-0 m-auto w-6 h-6 cursor-pointer"
            {...zoomDownPressProps}
          />
        </div>

        {availableSizes.map((availSize, index) => (
          <div key={index} className={clsx('h-12 w-6 relative text-gray-400')}>
            {props.size === availSize ? (
              <GoDotFill className={clsx('absolute inset-0 m-auto w-1/2 h-1/2')} />
            ) : (
              <GoDot className={clsx('absolute inset-0 m-auto w-1/2 h-1/2')} />
            )}
          </div>
        ))}
        <div className="h-12 w-6 relative">
          <MdOutlineZoomIn
            className="scale-x-[-1] absolute inset-0 m-auto w-6 h-6 cursor-pointer"
            {...zoomUpPressProps}
          />
        </div>
      </div>
    </>
  )
}

export default ZoomSelector

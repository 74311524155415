import { Routes, Route, BrowserRouter } from 'react-router-dom'
import appRoutes from './Routes'
import './style.css'
import { ClientConfig } from './config/client.config'
import RoutesTokenInterceptor from './RoutesTokenInterceptor'
import { Nav, ProtectedRoute, Recaptcha } from './components'
import React from 'react'
import { AppProvider } from './providers/AppContext'
import { QueryClient } from '@tanstack/react-query'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { AuthProvider } from './providers/AuthContext'

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 1000 * 60 * 60 * 24, // 24 hours
        staleTime: Infinity, // Data is never considered stale
      },
    },
  })

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  })

  return (
    <>
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <AppProvider>
          <AuthProvider>
            <BrowserRouter>
              <div className="flex flex-col overflow-y-hidden h-screen">
                <Nav></Nav>
                <div className="flex-grow overflow-y-auto overflow-x-hidden">
                  <Routes>
                    <Route path="/auth" element={<RoutesTokenInterceptor />} />
                    {appRoutes
                      .filter(ar => ar.enabled)
                      .map(route =>
                        !route.authRequired ? (
                          <Route key={route.key} path={route.path} element={<route.component />} />
                        ) : (
                          <Route
                            key={route.key}
                            path={route.path}
                            element={
                              <ProtectedRoute>
                                <route.component />
                              </ProtectedRoute>
                            }
                          />
                        ),
                      )}
                  </Routes>
                </div>
              </div>
            </BrowserRouter>
          </AuthProvider>
        </AppProvider>
      </PersistQueryClientProvider>
      {ClientConfig.publicRecaptchaEnabled && <Recaptcha />}
    </>
  )
}

export default App

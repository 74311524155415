import { TApiResponse, TEmbyBaseItemDto, TEmbyRequestOptions } from 'app-platform-shared-types'
import { clientFetch } from './client-fetch'
import qs from 'qs'

export const getMediaItems = async (
  options?: TEmbyRequestOptions,
): Promise<TApiResponse<TEmbyBaseItemDto> | undefined> => {
  const qString = qs.stringify(options, { encodeValuesOnly: true })
  // console.log('options', options)
  // console.log('qstring', qString)
  // console.log('emby-fetch url', `/emby/Items?${qString}`)
  const embyApiResult = await clientFetch<TEmbyBaseItemDto>(`/emby/Items?${qString}`, {
    method: 'get',
  })

  let result
  if (embyApiResult) {
    result = {
      data: embyApiResult.data,
      meta: embyApiResult.meta,
    } as TApiResponse<TEmbyBaseItemDto>
  }

  return result
}

export const getMediaItem = async (
  options: TEmbyRequestOptions,
): Promise<TApiResponse<TEmbyBaseItemDto> | undefined> => {
  // console.log('options', options)
  // console.log('getMediaItem url', `/emby/Items/${options.itemId}`)
  const embyApiResult = await clientFetch<TEmbyBaseItemDto>(`/emby/Items/${options.itemId}`, {
    method: 'get',
  })

  let result
  if (embyApiResult) {
    result = {
      data: embyApiResult.data,
      meta: embyApiResult.meta,
    } as TApiResponse<TEmbyBaseItemDto>
  }

  return result
}

export const getMediaUsers = async (options?: TEmbyRequestOptions) => {
  const qString = qs.stringify(options, { encodeValuesOnly: true })
  // console.log('getMediaUsers url', `/emby/Users/?${qString}`)
  const embyApiResult = await clientFetch<TEmbyBaseItemDto>(`/emby/Users?${qString}`, {
    method: 'get',
  })

  let result
  if (embyApiResult) {
    result = {
      data: embyApiResult.data,
      meta: embyApiResult.meta,
    } as TApiResponse<TEmbyBaseItemDto>
  }

  return result
}

import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { Locale } from '../config/i18n.config'
import { TZoomSizes } from 'app-platform-shared-types'
import { useTranslation } from 'react-i18next'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export type TAppContext = {
  locale: Locale
  zoomSize: TZoomSizes
  showDebug: boolean
  setLocale: Dispatch<SetStateAction<Locale>>
  setZoomSize: Dispatch<SetStateAction<TZoomSizes>>
  setShowDebug: Dispatch<SetStateAction<boolean>>
}

const AppContext = createContext<TAppContext | undefined>(undefined)

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation()
  const [showDebug, setShowDebug] = useState<boolean>(() => {
    const d = localStorage.getItem('showDebug') ?? 'false'
    return d === 'true'
  })

  const [locale, setLocale] = useState<Locale>(() => {
    const l = localStorage.getItem('selectedLanguage')
    const result = (l ?? 'en') as Locale
    i18n.changeLanguage(result)
    return result
  })
  const [zoomSize, setZoomSize] = useState<TZoomSizes>(() => {
    const z = localStorage.getItem('selectedZoomSize')
    return (z ?? 'md') as TZoomSizes
  })

  // Update localStorage whenever `locale` or `zoomSize` changes
  useEffect(() => {
    localStorage.setItem('selectedLanguage', locale)
    i18n.changeLanguage(locale)

    localStorage.setItem('selectedZoomSize', zoomSize)
    localStorage.setItem('showDebug', showDebug.toString())
  }, [i18n, locale, zoomSize, showDebug])

  return (
    <div>
      {showDebug && <ReactQueryDevtools />}
      <AppContext.Provider value={{ locale, zoomSize, showDebug, setLocale, setZoomSize, setShowDebug }}>
        {children}
      </AppContext.Provider>
    </div>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider')
  }
  return context
}

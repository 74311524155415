import { TEmbyBaseItemDto } from 'app-platform-shared-types'
import { tv, VariantProps } from 'tailwind-variants'
import { getItemImgWithETag } from '../../../../lib/helpers/emby.helper'
import { FaStar } from 'react-icons/fa6'
import { hourMinsFromTicks } from '../../../../lib/helpers/utility.helper'
import { useTranslation } from 'react-i18next'
import AriaButton from '../../../../components/core/AriaButton'
import ExternalProviders from './ExternalProviders'
import clsx from 'clsx'
import { useAppContext } from '../../../../providers'

const MediaItemBannerTV = tv({
  slots: {
    base: 'relative w-full flex flex-col sm:flex-row justify-center gap-2  ',
    text: 'text-white',
    primaryImgContainer: 'min-w-[25%] flex flex-row justify-center relative p-3',
    primaryImg: 'max-h-[475px] w-auto',
    logoImg: 'w-full pb-3 flex flex-row justify-center sm:justify-start',
    data: 'p-6 sm:px-0',
  },
  variants: {
    size: {
      xs: { text: 'text-xs' },
      sm: { text: 'text-sm' },
      md: { text: 'text-md' },
      lg: { text: 'text-lg' },
      xl: { text: 'text-xl' },
      xxl: { text: 'text-2xl' },
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type MediaItemBannerVariants = VariantProps<typeof MediaItemBannerTV>

interface MediaItemBannerProps extends MediaItemBannerVariants {
  item: TEmbyBaseItemDto
}

const MediaItemBanner: React.FC<MediaItemBannerProps> = (props: MediaItemBannerProps) => {
  const { base, data, logoImg, primaryImgContainer, primaryImg, text } = MediaItemBannerTV(props)
  const { t } = useTranslation()

  const primaryUrl = getItemImgWithETag(props.item.Id, props.item.ImageTags.Primary, 90, 'Primary')
  const logoUrl = getItemImgWithETag(props.item.Id, props.item.ImageTags.Logo, 90, 'Logo')

  const { zoomSize } = useAppContext()

  return (
    <>
      <div className={clsx(base(props))}>
        <div className={primaryImgContainer(props)}>
          <img src={primaryUrl} className={clsx(primaryImg(props))} alt="Primary" />
        </div>
        <div className={data(props)}>
          <div className="flex flex-col w-full h-full">
            {props.item.ImageTags.Logo && (
              <div className={logoImg(props)}>
                <img src={logoUrl} className="h-[75px]" alt="Logo" />
              </div>
            )}
            <div className={clsx('flex flex-row')}>
              <div className={clsx('flex flex-row gap-1 items-center w-full justify-around', text(props))}>
                <div className={clsx('flex flex-row items-center gap-2')}>
                  <FaStar className="text-red-900" />
                  <div>{Math.round(props.item.CommunityRating * 10.0) / 10}</div>
                </div>
                <div>{hourMinsFromTicks(props.item.RunTimeTicks)}</div>
              </div>
            </div>
            <div className={clsx('flex flex-col w-full p-2', text(props))}>
              {props.item.Taglines?.length > 0 && <div>{props.item.Taglines[0]}</div>}
              <div>{props.item.Overview}</div>
            </div>
            <div className="flex-grow flex flex-col justify-end">
              <div className="flex flex-row w-full p-2">
                {props.item.Genres?.length > 0 || Object.keys(props.item.ProviderIds)?.length > 0 ? (
                  <div className="flex flex-col w-full justify-between ">
                    {props.item.Genres?.length > 0 ? (
                      <>
                        <div
                          className="w-full flex flex-row justify-start items-center flex-wrap gap-1 "
                          aria-label={t('emby.genres')}
                        >
                          {props.item.Genres.map(g => (
                            <AriaButton
                              key={g}
                              className={clsx(
                                'flex flex-row justify-between items-center gap-1 m-1 px-2 rounded-lg',
                                text(props),
                              )}
                              theme="primary"
                            >
                              {g}
                            </AriaButton>
                          ))}
                        </div>
                      </>
                    ) : null}
                    {Object.keys(props.item.ProviderIds)?.length > 0 ? (
                      <ExternalProviders providerIds={props.item.ProviderIds} size={zoomSize}></ExternalProviders>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MediaItemBanner

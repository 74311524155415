import PoolActivityAddEditForm from './_components/PoolActivityAddEditForm'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { Button, Dialog, LoadingOverlay, Collapsible } from '../../../../components'
import { ClientConfig } from '../../../../config/client.config'
import { getPoolActivityLocaleText, renderActivityEmails } from '../../../../lib/helpers/pool.helper'
import { orderBy } from 'lodash'
import { RiMailSendFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PoolLayout from '../../../../layouts/PoolLayout'
import PoolMemberCard from '../_components/PoolMemberCard'
import { getLargestCmsAssetMeta } from '../../../../lib/helpers/csm-asset.helper'
import { NIL as EMPTY_GUID } from 'uuid'
import {
  createPoolActivityEmails,
  getPool,
  getPoolActivity,
  getPoolActivityEmails,
  getPoolMembers,
  markSuccessPoolActivityEmail,
  sendPoolActivityEmail,
} from '../../../../lib/api/pool-fetch'
import {
  TEmailDto,
  TPoolActivityActionVerb,
  TPoolDto,
  TPoolActivityDto,
  TPoolMemberDto,
  TPoolActivityEmailCreateMeta,
  TCmsAsset,
  TFlattenedTxRow,
  TTransactionDto,
  TPoolActivityTransactionDto,
} from 'app-platform-shared-types'
import ActivityTransactionRow from './_components/ActivityTransactionRow'
import ActivityTransactionRowHeader from './_components/ActivityTransactionRowHeader'
import ActivityEmailHeaderRow from './_components/ActivityEmailHeaderRow'
import ActivityEmailRow from './_components/ActivityEmailRow'
import ActivityEmailDetails from './_components/ActivityEmailDetails'
import ActivityNotificationEmail from '../../../../components/emailTemplates/ActivityNotificationEmail'
import { useAuthContext } from '../../../../providers'

interface PoolActivityProps {}

type TShowEmailDetails = {
  show: boolean
  email: TEmailDto | undefined
  transaction: TTransactionDto | undefined
}

const Activity: React.FC<PoolActivityProps> = () => {
  const { authUser } = useAuthContext()
  // console.log('{ params: { pool.id, activity, action }', { pool.id, activity, action })
  const { poolId } = useParams<{ poolId: string }>()
  const { activityId } = useParams<{ activityId: string }>()
  const { action } = useParams<{ action: TPoolActivityActionVerb }>()
  const actionWithDefault = action ?? 'view'

  if (!poolId || !activityId) {
    throw new Error('ActivityTSX - Invalid Access')
  }

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [isPoolOwner, setIsPoolOwner] = useState<boolean>(false)
  const [pool, setPool] = useState<TPoolDto | undefined>()
  const [activity, setActivity] = useState<TPoolActivityDto | undefined>()
  const [allPoolMembers, setAllPoolMembers] = useState<Array<TPoolMemberDto>>([])
  const [flattenedTxRows, setFlattenedTxRows] = useState<Array<TFlattenedTxRow>>([])
  const [pageTitle] = useState<string>(t('pool.titleActivity'))

  const [showEmailWarnDialog, setShowEmailWarnDialogue] = useState<boolean>(false)
  const [currActivityEmails, setCurrActivityEmails] = useState<Array<TEmailDto>>([])
  const [emailCount, setEmailCount] = useState<number>(0)
  const [showEmailDetails, setShowEmailDetails] = useState<TShowEmailDetails>({
    show: false,
    email: undefined,
    transaction: undefined,
  })

  const [isSending, setIsSending] = useState<boolean>(false)
  const [isPoolLoading, setIsPoolLoading] = useState<boolean>(true)
  const [isPoolMemberLoading, setIsPoolMemberLoading] = useState<boolean>(true)
  const [isPoolActivityLoading, setIsPoolActivityLoading] = useState<boolean>(true)
  const [isActivityEmailsLoading, setIsActivityEmailsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (poolId) {
      getPool(poolId).then(
        pool => {
          if (!pool) {
            throw new Error('Invalid pool')
          }
          setPool(pool)
          const isPoolOwner = authUser?.id === pool?.userId
          setIsPoolOwner(isPoolOwner)
          if (actionWithDefault === 'edit' && !isPoolOwner) {
            navigate('/401')
          }

          setIsPoolLoading(false)
        },
        err => {
          console.error('Activity: error retrieving pool', err)
          setIsPoolMemberLoading(false)
        },
      )
      getPoolMembers(poolId).then(
        pms => {
          setAllPoolMembers(pms)
          setIsPoolMemberLoading(false)
        },
        err => {
          console.error('Activity: error retrieving pool members', err)
          setIsPoolMemberLoading(false)
        },
      )
    }

    if (activityId === EMPTY_GUID && actionWithDefault === 'add') {
      setActivity({
        id: activityId,
        type: 'ACCOUNT_DEBIT',
        targetPoolDateIso: DateTime.fromJSDate(new Date()).toISO(),
      } as TPoolActivityDto)
      setIsPoolActivityLoading(false)
    } else if (poolId && activityId && activityId !== EMPTY_GUID) {
      getPoolActivity(poolId, activityId).then(
        a => {
          setActivity(a)
          setIsPoolActivityLoading(false)
        },
        err => {
          console.error('Activity: error retrieving pool activity', err)
          setIsPoolActivityLoading(false)
        },
      )
    } else {
      setIsPoolActivityLoading(false)
    }

    if (poolId && activityId !== EMPTY_GUID) {
      getPoolActivityEmails(poolId, activityId).then(
        emails => {
          setCurrActivityEmails(emails?.data ?? [])
          setEmailCount(emails?.data?.length ?? 0)
          setIsActivityEmailsLoading(false)
        },
        err => {
          console.error('Activity: error retrieving pool activity emails', err)
          setIsActivityEmailsLoading(false)
        },
      )
    } else {
      setIsActivityEmailsLoading(false)
    }
  }, [poolId, activityId, actionWithDefault, navigate, authUser?.id])

  // flatten the transactions for easier sort
  useEffect(() => {
    const transactions = activity?.transactions?.map(t => t.transaction)
    const flattened: Array<TFlattenedTxRow> = []
    transactions?.forEach(t => {
      const currPoolMember = allPoolMembers?.find(apm => apm.id === t.poolMemberId)
      if (currPoolMember) {
        const flattenedRow: TFlattenedTxRow = {
          id: t.id,
          memberDisplayName: currPoolMember?.displayName ?? '-',
          date: DateTime.fromISO(t.dateIso).toJSDate(),
          initialBal: t.initialBal,
          endBal: t.endBal,
          creditDebitAmt: t.creditDebitAmt,
        }

        flattened.push(flattenedRow)
      }
    })
    const sorted = orderBy(flattened, ['date', 'memberDisplayName'], ['desc', 'asc'])
    setFlattenedTxRows(sorted)
  }, [activity, allPoolMembers])

  const sortedActivityMembers = orderBy(activity?.poolMembers?.map(pm => pm.poolMember) ?? [], ['displayName'])

  const activityTypeName = activity?.type ? getPoolActivityLocaleText(activity.type) : t('pool.activity.accountDebit')

  const onDone = (activityId: string) => {
    // console.log('Activity onDone activityId', activityId)
    navigate(`/pools/${poolId}/activity/${activityId}`)
  }

  const onCancel = () => {
    // console.log('Activity onCancel activityId')
    navigate(-1)
  }

  const handleNotifyMembers = async (): Promise<void> => {
    try {
      setIsSending(true)
      setShowEmailWarnDialogue(false)

      if (!activity) {
        throw new Error('ActivityTSX: Invalid Activity')
      }

      // render the emails.
      const renderedEmails: Array<TPoolActivityEmailCreateMeta> = await renderActivityEmails(pool?.name ?? '', activity)
      const createdEmails = await createPoolActivityEmails(poolId, activityId, renderedEmails)

      const combinedEmails = [...createdEmails, ...currActivityEmails]
      setCurrActivityEmails(combinedEmails)
      setEmailCount(combinedEmails.length)

      const sentPromises = []
      const successfulSend: Array<TEmailDto> = []
      const failedEmails: Array<TEmailDto> = []
      const bulkUpdateEmailIds: Array<string> = []
      for (const createdEmail of createdEmails) {
        try {
          if (createdEmail.to?.length) {
            const updtPromise = sendPoolActivityEmail(poolId, createdEmail.id).then(
              updtEmail => {
                if (!updtEmail) {
                  failedEmails.push(createdEmail)
                  throw new Error(`Error processing email id: ${createdEmail.id} Continuing with others`)
                }

                const matchedEmailIndex = combinedEmails.findIndex(cae => cae.id === updtEmail.id)
                if (matchedEmailIndex !== -1) {
                  combinedEmails.splice(matchedEmailIndex, 1, updtEmail)
                }
                successfulSend.push(updtEmail)

                // const test = sentPromises.length
                setIsSendingText(
                  t('pool.activity.creditDebitNotification.isSendingDialogXofYText', {
                    X: successfulSend.length,
                    Y: createdEmails.length,
                  }),
                )
              },
              error => {
                failedEmails.push(createdEmail)
                console.error(`SEND EMAIL FAILURE: ${error}`)
              },
            )

            sentPromises.push(updtPromise)
          } else {
            // No EMAIL Address. We just store the email in the DB without sending it
            bulkUpdateEmailIds.push(createdEmail.id)
          }
        } catch (err) {
          console.error(`Activity handleNotifyMembers:`, err)
          alert('Error sending email.... continuing')
        }
      }

      if (bulkUpdateEmailIds.length) {
        await markSuccessPoolActivityEmail(poolId, bulkUpdateEmailIds).then(
          updtEmails =>
            updtEmails?.data.forEach(updtEmail => {
              const matchedEmailIndex = combinedEmails.findIndex(cae => cae.id === updtEmail.id)
              if (matchedEmailIndex !== -1) {
                combinedEmails.splice(matchedEmailIndex, 1, updtEmail)
              }
              successfulSend.push(updtEmail)
            }),
          err => console.error(`Activity.tsx: failure to add ${err}`),
        )
      }

      // const resolved =
      await Promise.all(sentPromises)
      // console.log(`ActivityTSX - Send Emails All Promises Resolved`, resolved)
      if (failedEmails.length) {
        console.error('Some Emails failed', failedEmails)
        alert('One or more emails failed to send. Please refresh the page to assess the damage.')
      }
      setCurrActivityEmails([...combinedEmails])
      setIsSending(false)
    } catch (err) {
      console.error(`Activity handleNotifyMembers:`, err)
      alert('Error sending email.... please refresh and try again')
      setIsSending(false)
    }
    setIsSendingText('')
  }

  const [isSendingText, setIsSendingText] = useState<string>(
    t('pool.activity.creditDebitNotification.isSendingDialogText'),
  )

  return (
    <>
      <LoadingOverlay
        text={t('common.loading')}
        show={isPoolLoading || isPoolMemberLoading || isPoolActivityLoading || isActivityEmailsLoading}
      />
      <PoolLayout bannerTitleText={pageTitle}>
        <LoadingOverlay show={isSending} text={isSendingText} />
        {actionWithDefault === 'view' && (
          <div className="px-1 py-3 w-full">
            <div className="flex justify-center">
              <Link className="text-3xl cursor-pointer " to={`/pools/${pool?.id}/`}>
                {pool?.name ?? ''}
              </Link>
            </div>
            <div className="flex flex-row justify-between text-2xl gap-4 my-3">
              <div>{activityTypeName}</div>
              <div>${activity?.creditDebitAmt ?? 0}</div>
              <div>
                {activity?.targetPoolDateIso?.length &&
                  DateTime.fromISO(activity.targetPoolDateIso).toLocaleString(DateTime.DATETIME_SHORT)}
              </div>
            </div>
            {activity?.note?.length && (
              <div className="text-center m-3">
                <div>{activity.note}</div>
              </div>
            )}
            <div className="flex flex-row flex-wrap gap-2 justify-center my-4">
              {activity?.cmsAsset?.cmsAssetsMeta?.length ? (
                <>
                  {activity.cmsAsset.cmsAssetsMeta.map((a: TCmsAsset) => {
                    const assetMeta = getLargestCmsAssetMeta(a)
                    if (!assetMeta) {
                      throw new Error('MISSING CMS ASSET')
                    }
                    return (
                      // EXTERNAL LINK TO CMS use <a>
                      <a
                        key={a.id}
                        href={`${ClientConfig.publicCmsUrl}${assetMeta.url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={`${ClientConfig.publicCmsUrl}${a.cmsAssetMetaFormats.thumbnail.url}`}
                          alt={a.cmsAssetMetaFormats.thumbnail.name}
                          height={a.cmsAssetMetaFormats.thumbnail.height}
                          width={a.cmsAssetMetaFormats.thumbnail.width}
                        />
                      </a>
                    )
                  })}
                </>
              ) : null}
            </div>
            <div className="w-full mb-4">
              <Collapsible
                className="border-t border-shadow py-2"
                defaultCollapsed={false}
                leftSlot={<div className="text-2xl">{t('pool.activity.activityMemebers')}</div>}
                rightSlot={
                  <div className="text-2xl  h-9 w-9 flex justify-center items-center bg-accent text-white rounded-4xl">
                    <span>{activity?.poolMembers?.length ?? ''}</span>
                  </div>
                }
              >
                <div className="flex flex-row flex-wrap justify-center">
                  {sortedActivityMembers.map(pm => (
                    <div key={pm.id} className="w-[175px]">
                      <PoolMemberCard key={pm.id} poolMember={pm} hideBalance={true} />
                    </div>
                  ))}
                </div>
              </Collapsible>
            </div>

            <div className="w-full mb-4">
              <Collapsible
                className="border-t border-shadow py-2"
                defaultCollapsed={true}
                leftSlot={<div className="text-2xl">{t('pool.activity.activityTransactions')}</div>}
                rightSlot={
                  <div className="text-2xl  h-9 w-9 flex justify-center items-center bg-accent text-white rounded-4xl">
                    <span>{activity?.transactions?.length ?? ''}</span>
                  </div>
                }
              >
                <div className="flex flex-row flex-wrap justify-center">
                  <ActivityTransactionRowHeader />
                  {flattenedTxRows?.map(t => <ActivityTransactionRow key={t.id} row={t} />)}
                </div>
              </Collapsible>
            </div>

            <div className="w-full mb-4">
              <Collapsible
                className="border-t border-shadow py-2"
                defaultCollapsed={true}
                leftSlot={<div className="text-2xl">{t('pool.activity.activityNotifications')}</div>}
                rightSlot={
                  <div className="text-2xl  h-9 w-9 flex justify-center items-center bg-accent text-white rounded-4xl">
                    <span>{emailCount ?? 0}</span>
                  </div>
                }
              >
                <div className="flex flex-row flex-wrap justify-center">
                  <ActivityEmailHeaderRow />
                  {pool &&
                    currActivityEmails?.length > 0 &&
                    currActivityEmails.map(e => {
                      const assocTx: TPoolActivityTransactionDto | undefined = activity?.transactions.find(
                        t => t.transactionId === e.transactionId,
                      )
                      // console.log('Activity notification activity', activity)
                      // console.log('Activity notification assocTx', assocTx)
                      return (
                        <ActivityEmailRow
                          key={e.id}
                          email={e}
                          pool={pool}
                          onInfoClick={(email: TEmailDto) =>
                            setShowEmailDetails({
                              show: true,
                              email,
                              transaction: assocTx?.transaction,
                            })
                          }
                        />
                      )
                    })}
                </div>
              </Collapsible>
              <Dialog
                isOpen={showEmailDetails.show}
                dialogTitle={t('pool.activity.activityEmailDetails')}
                actionButtonClick={() => {
                  // console.log('Activity show notification dialog: showEmailDetails', showEmailDetails)
                  setShowEmailDetails({
                    ...showEmailDetails,
                    show: false,
                  })
                }}
                cancelButtonClick={() => {
                  // console.log('Activity show notification dialog: showEmailDetails', showEmailDetails)
                  setShowEmailDetails({
                    ...showEmailDetails,
                    show: false,
                  })
                }}
              >
                {activity &&
                showEmailDetails.email &&
                showEmailDetails.email.poolMemberId &&
                showEmailDetails.email.transactionId ? (
                  <ActivityNotificationEmail
                    excludeOuterHtml={true}
                    activity={activity}
                    poolName={pool?.name ?? ''}
                    receiverPoolMember={allPoolMembers.find(
                      pm => showEmailDetails.email && pm.id === showEmailDetails.email.poolMemberId,
                    )}
                    receiverTransaction={
                      activity.transactions.find(
                        t => showEmailDetails.email && showEmailDetails.email.transactionId === t.transactionId,
                      )?.transaction
                    }
                  />
                ) : (
                  <ActivityEmailDetails email={showEmailDetails.email} />
                )}
              </Dialog>
            </div>

            <div className="flex flex-row justify-between items-center gap-1">
              {isPoolOwner ? (
                <>
                  <Button
                    className="p-2 flex flex-row items-center"
                    onPress={() => setShowEmailWarnDialogue(true)}
                    theme="neutral"
                  >
                    <RiMailSendFill />
                    &nbsp;{t('pool.activity.notifyMembers')}
                  </Button>
                  <Dialog
                    dialogTitle={t('pool.activity.creditDebitNotification.confirmSendDialogTitle')}
                    isOpen={showEmailWarnDialog}
                    actionButtonClick={handleNotifyMembers}
                    cancelButtonClick={() => {
                      setShowEmailWarnDialogue(false)
                    }}
                  >
                    <>{t('pool.activity.creditDebitNotification.confirmSendEmail')}</>
                  </Dialog>
                </>
              ) : (
                <span></span>
              )}

              {isPoolOwner && (
                <Button className="p-2" theme="secondary" onPress={() => navigate('edit')}>
                  Edit
                </Button>
              )}
            </div>
          </div>
        )}
        {(actionWithDefault === 'add' || actionWithDefault === 'edit') && poolId?.length && activity !== undefined && (
          <PoolActivityAddEditForm
            poolId={poolId}
            activity={activity}
            allPoolMembers={allPoolMembers}
            action={actionWithDefault}
            onDone={onDone}
            onCancel={onCancel}
          ></PoolActivityAddEditForm>
        )}
      </PoolLayout>
    </>
  )
}

export default Activity

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import clsx from 'clsx'
import { FaChevronDown } from 'react-icons/fa6'
import { tv, VariantProps } from 'tailwind-variants'

const listBoxTV = tv({
  slots: {
    base: 'relative w-full bg-white',
    button:
      'relative w-full text-md cursor-default rounded-lg  py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-accent focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-accent ',
  },
  variants: {
    size: {
      xs: {
        base: 'max-w-[125px]',
      },
      sm: {
        base: 'max-w-[150px]',
      },
      md: {
        base: 'max-w-[175px]',
      },
      lg: {
        base: 'max-w-[200px]',
      },
      xl: {
        base: 'max-w-[225px]',
      },
      xxl: {
        base: 'max-w-[250px]',
      },
    },

    theme: {
      primary: {
        base: 'text-accent ',
      },
      secondary: {
        base: 'text-midtone ',
      },
      neutral: {
        base: 'text-shadow',
      },
      danger: {
        base: 'text-red-600',
      },
    },
  },
  defaultVariants: {
    size: 'md',
    theme: 'neutral',
  },
})

type ListBoxVariants = VariantProps<typeof listBoxTV>

interface ListBoxProps extends ListBoxVariants {
  value: string
  values: Array<string>
  onChange?: (val: string) => void
  rootElemClassName?: string
}

const DropDownListBox: React.FC<ListBoxProps> = (props: ListBoxProps): React.ReactElement => {
  const { button, base } = listBoxTV(props)

  return (
    <div className={clsx(base(props), props.rootElemClassName)}>
      <Listbox value={props.value} onChange={props.onChange}>
        <ListboxButton className={button(props)}>
          <span className="block truncate">{props.value}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <FaChevronDown className="h-5 w-5 text-shadow" aria-hidden="true" />
          </span>
        </ListboxButton>
        <ListboxOptions className="z-20 absolute w-full overflow-y-auto border bg-white flex flex-col text-center">
          {props.values.map(p => (
            <ListboxOption className="w-full hover:bg-highlight cursor-pointer " key={p} value={p} disabled={false}>
              {p}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </div>
  )
}

export default DropDownListBox

import { useState } from 'react'
import BreadCrumbs, { TBreadCrumb } from '../components/core/BreadCrumbs'
import { getPoolBreadCrumbs } from '../lib/helpers/pool.helper'
import { useParams } from 'react-router-dom'

interface PoolLayoutProps {
  bannerTitleText: string
  children: React.ReactNode
}

const PoolLayout: React.FC<PoolLayoutProps> = (props: PoolLayoutProps) => {
  const { poolId } = useParams<{ poolId: string }>()
  const { poolMemberId } = useParams<{ poolMemberId: string }>()
  const { activityId } = useParams<{ activityId: string }>()
  const [breadcrumbs] = useState<Array<TBreadCrumb>>(getPoolBreadCrumbs(poolId, poolMemberId, activityId) ?? [])

  return (
    <>
      <div>
        <BreadCrumbs allBreadCrumbs={breadcrumbs} theme="secondary" textSize="lg"></BreadCrumbs>
      </div>
      <div className="flex w-full justify-center items-center bg-accent text-white text-3xl">
        {props.bannerTitleText}
      </div>
      <div className="grid grid-cols-[1fr] md:grid-cols-[2fr_6fr_2fr] gap-1">
        <div className="md:col-start-2 md:col-end-3 flex flex-wrap gap-2 w-full justify-center ">{props.children}</div>
      </div>
    </>
  )
}

export default PoolLayout
